import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, {Fragment, useCallback, useState} from "react";
import {Box, Button, ButtonGroup, DialogActions} from "@mui/material";
import FlagProcessor from "../text-to-map/FlagProcessor";
import ShareLinkNav from "../share-link/ShareLinkNav";
import ScoutsToTextItem from "../to-text/ScoutsToTextItem";
import SortButtons from "../map-building/SortButtons";
import DatabaseToggle from "../database/DatabaseToggle";
import {useSelector} from "react-redux";
import CopyScoutsToClipboard from "../to-text/CopyScoutsToClipboard";
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';

function ToolsPopUp() {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const scoutingMarks = useSelector((state) => state.scoutingMarks.value)
    const expansions = useSelector((state) => state.expansions.value)

    const copyToClipboardCallback = useCallback(() => {
        CopyScoutsToClipboard(expansions, scoutingMarks);
    });


    return (
        <Fragment>
            <Button startIcon={<BuildOutlinedIcon/>} variant="outlined" color="secondary" size="small" onClick={handleClickOpen}>
                {"Tools"}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box>
                    <DialogTitle id="alert-dialog-title">
                        {"Tools"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" component={'span'}>
                            <FlagProcessor />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <ButtonGroup label="tools" color="secondary" size="small" aria-label="primary button group">
                            <ShareLinkNav/>
                            <ScoutsToTextItem clipboard={copyToClipboardCallback}/>
                            <SortButtons/>
                            <DatabaseToggle/>
                        </ButtonGroup>
                    </DialogActions>
                </Box>
            </Dialog>
        </Fragment>
    )
}

export default ToolsPopUp