import ExpansionMap from "../data/ExpansionData";

export default function SortMaps(sortType, expansion) {
    let map = [...ExpansionMap[expansion].map]

    switch (sortType) {
        default:
        case "DEFAULT":
            break;
        case "INSTANCES-FIRST":
            map = map.sort(function (a, b) {
                let ai = a.instances !== undefined ? a.instances : 1;
                let bi = b.instances !== undefined ? b.instances : 1;
                return bi - ai
            })
            break;
        case "INSTANCES-LAST":
            map = map.sort(function (a, b) {
                let ai = a.instances !== undefined ? a.instances : 1;
                let bi = b.instances !== undefined ? b.instances : 1;
                return ai - bi
            })
            break;
        case "ALPHABET-DESC":
            map = map.sort(
                function compareFn(a, b) {
                    return a.fullname.localeCompare(b.fullname)
                }
            )
            break;
        case "ALPHABET-ASC":
            map = map.sort(
                function compareFn(a, b) {
                    return b.fullname.localeCompare(a.fullname)
                }
            )
            break;
    }

    return map

}