import {Grid, TextField} from "@mui/material";
import React from "react";
import {formatMapName} from "../util/Utility";
import ToggleSpot from "../map-building/coord-spots/ToggleSpot";
import ExpansionData from "../data/ExpansionData";
import {useDispatch, useSelector} from "react-redux";
import {setExpansions} from "../state-objects/ExpansionsSlice";

export default function FlagProcessor() {

    const scoutingMarks = useSelector((state) => state.scoutingMarks.value)
    const databaseOn = useSelector((state) => state.databaseOn.value)
    const databaseCode = useSelector((state) => state.databaseCode.value)

    const dispatch = useDispatch()

    const instances = ["", "", "", "", "", ""]
    const instanceLiteral = instances.join()

    function parseFlagData(map, text) {
        let details = {}

        let split = text.split("\n")

        for (let section in split) {
            let sentence = split[section]
            let currentMap = '';
            let inst = 1;
            let arank = 'UNK';
            let sec = '';

            let coord1 = -1
            let coord2 = -1
            for (let m in map) {
                const mapReg = map[m].fullname + "([\\s" + instanceLiteral + "]+)\\(\\s+([0-9.]+)\\s+,\\s+([0-9.]+)"
                let match = sentence.match(mapReg);

                if (match != null) {
                    currentMap = map[m].fullname;

                    coord1 = Number(match[2])
                    coord2 = Number(match[3])

                    for (let v in instances) {
                        let symbol = instances[v]
                        if (match[1].includes(symbol)) inst = Number(v) + 1
                    }

                    sec = m;
                }

                if (sentence.includes(map[m].arank1)) {
                    arank = "mark1"
                    break;
                } else if (sentence.includes(map[m].arank2)) {
                    arank = "mark2"
                    break;
                }
            }

            if (currentMap.length === 0) continue;

            let lowestVariance = 50
            let indexCoord = -1;
            for (let i = 0; i < map[sec].aranks.length; i++) {

                let x = map[sec].aranks[i].x;
                let y = map[sec].aranks[i].y;

                let vari = Math.pow(coord1 - x, 2) + Math.pow(coord2 - y, 2)

                if (vari < lowestVariance) {
                    lowestVariance = vari;
                    indexCoord = i;
                }
            }

            if (indexCoord === -1) continue;

            let instancedSection = sec + '-' + inst;
            if (details[instancedSection] === undefined) details[instancedSection] = {}

            details[instancedSection][indexCoord] = arank
        }

        return details
    }

    function assignSpots(parsedFlagData, expansion, expansionMapJson) {
        for (let inGameFlag in expansionMapJson) {
            let mapDetails = expansionMapJson[inGameFlag]
            let instantCount = mapDetails.instances === undefined ? 1 : mapDetails.instances;

            for (let i = 0; i < instantCount; i++) {
                let parsedScoutingSpot = parsedFlagData[inGameFlag + "-" + (i + 1)]
                if (parsedScoutingSpot === undefined) continue;

                let instancedMap = scoutingMarks[formatMapName(expansion, mapDetails.name, i + 1)]

                let existingARank1 = -1;
                let existingARank2 = -1;
                for (let scoutedMark in instancedMap) {
                    let existingScout = instancedMap[scoutedMark];
                    if (existingScout["status"] === "mark1") existingARank1 = existingScout["index"]
                    else existingARank2 = existingScout["index"]
                }

                let reflaggedARank1 = -1;
                let reflaggedARank2 = -1;

                for (let markSpot in parsedScoutingSpot) {
                    let parsedARankName = parsedScoutingSpot[markSpot]
                    if (parsedARankName === "UNK") {
                        if (reflaggedARank1 === -1 && existingARank1 === -1) reflaggedARank1 = Number(markSpot);
                        else reflaggedARank2 = Number(markSpot);
                    } else if (parsedARankName === "mark1") {
                        if (reflaggedARank1 !== -1) reflaggedARank2 = Number(reflaggedARank1)
                        reflaggedARank1 = Number(markSpot)
                    } else {
                        reflaggedARank2 = Number(markSpot)
                    }
                }

                if (reflaggedARank1 === -1) reflaggedARank1 = Number(existingARank1);
                if (reflaggedARank2 === -1) reflaggedARank2 = Number(existingARank2);

                let currentTime = (new Date()).getTime();
                if (reflaggedARank1 !== -1) {
                    ToggleSpot(expansion, mapDetails.name, i + 1, Number(reflaggedARank1), "mark1", currentTime, scoutingMarks, databaseOn, databaseCode, dispatch)
                }
                if (reflaggedARank2 !== -1 && expansion !== "arr") {
                    ToggleSpot(expansion, mapDetails.name, i + 1, Number(reflaggedARank2), "mark2", currentTime, scoutingMarks, databaseOn, databaseCode, dispatch)
                }

                if (existingARank1 !== -1 && reflaggedARank1 !== existingARank1 && reflaggedARank2 !== existingARank1) {
                    ToggleSpot(expansion, mapDetails.name, i + 1, Number(existingARank1), "remove", currentTime, scoutingMarks, databaseOn, databaseCode, dispatch)
                }

                if (existingARank2 !== -1 && reflaggedARank1 !== existingARank2 && reflaggedARank2 !== existingARank2) {
                    ToggleSpot(expansion, mapDetails.name, i + 1, Number(existingARank2), "remove", currentTime, scoutingMarks, databaseOn, databaseCode, dispatch)
                }

            }
        }
    }

    function parseText() {
        let text = document.getElementById("flag-text").value;
        let exp = []
        for (let expansion in ExpansionData) {
            let expansionData = ExpansionData[expansion].map;

            let parsedData = parseFlagData(expansionData, text)

            if (Object.keys(parsedData).length > 0) {
                exp.push(expansion)
            }

            assignSpots(parsedData, expansion, expansionData)
        }

        for (let sp in scoutingMarks) {
            let expansion = sp.split("-")[0]
            if (!exp.includes(expansion)) exp.push(expansion)
        }

        dispatch(setExpansions(exp))
    }


    return (
        <Grid xs={14} m={1}>
            <TextField
                id="flag-text"
                label="Flags"
                InputLabelProps={{
                    shrink: true,
                }}

                inputProps={{style: {color: "black", resize: 'none'}}}
                multiline
                minRows={6}
                maxRows={6}
                type="text"
                defaultValue=""
                size="small"
                onChange={() => {
                    parseText()
                }}
                variant="outlined"

            />
        </Grid>

    )
}