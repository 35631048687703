const ArrMap = [{
    key: 'mln',
    fullname: "Middle La Noscea",
    name: "mln",
    aetherytes: [{
        name: "Summerford Farms",
        x: 26,
        y: 15.9
    }],
    arank1: "Vogaal Ja",
    aranks: [{
        x: 16.55,
        y: 7.9,
    }, {
        x: 18.6,
        y: 9.45,
    }, {
        x: 15.95,
        y: 10.85,
    }, {
        x: 17.15,
        y: 11.5,
    }, {
        x: 13.9,
        y: 11.65,
    }, {
        x: 18.35,
        y: 13.3,

    }, {
        x: 13.9,
        y: 13.6,

    }, {
        x: 20.4,
        y: 13.75,

    }, {
        x: 16.35,
        y: 14.0,

    }, {
        x: 15.2,
        y: 15.3,

    }, {
        x: 20.75,
        y: 15.6,

    }, {
        x: 18.7,
        y: 16.3,

    }, {
        x: 23.3,
        y: 17.0,

    }, {
        x: 17.15,
        y: 17.15,

    }, {
        x: 18.45,
        y: 18.25,

    }, {
        x: 21.0,
        y: 18.3,

    }, {
        x: 23.9,
        y: 19.7,

    }, {
        x: 19.25,
        y: 20.3,

    }, {
        x: 24.4,
        y: 20.95,

    }, {
        x: 20.25,
        y: 21.3,

    }, {
        x: 19.9,
        y: 22.4,

    }, {
        x: 22.9,
        y: 22.55,

    }, {
        x: 25.1,
        y: 23.45,

    }, {
        x: 23.2,
        y: 24.2,

    }]
}, {
    key: 'lln',
    name: "lln",
    fullname: "Lower La Noscea",
    instances: 1,
    arank1: "Unktehi",
    aetherytes: [{
        name: "Moraby Drydocks",
        x: 24.5,
        y: 34.5
    }],
    aranks: [{
        x: 30.05,
        y: 14.3,

    }, {
        x: 33,
        y: 16.05,

    }, {
        x: 31.5,
        y: 16.45,

    }, {
        x: 29,
        y: 17,

    }, {
        x: 33.5,
        y: 17.5,

    }, {
        x: 28.55,
        y: 18.2,

    }, {
        x: 28.65,
        y: 20.5,

    }, {
        x: 25.1,
        y: 22.4,

    }, {
        x: 25.95,
        y: 24.85,

    }, {
        x: 26.45,
        y: 26.3,

    }, {
        x: 24.3,
        y: 26.55,

    }, {
        x: 20.25,
        y: 32,

    }, {
        x: 20.1,
        y: 34.3,

    }, {
        x: 22.7,
        y: 34.5,

    }, {
        x: 16.9,
        y: 35.35,

    }, {
        x: 19.2,
        y: 35.4,

    }, {
        x: 20.6,
        y: 35.6,

    }, {
        x: 23.15,
        y: 36,

    }, {
        x: 20,
        y: 37.5,

    }, {
        x: 22.9,
        y: 38.2,

    }]
}, {
    key: 'eln',
    name: "eln",
    count: 20,
    fullname: "Eastern La Noscea",
    arank1: "Hellsclaw",
    aetherytes: [{
        name: "Costa del Sol",
        x: 31.2,
        y: 30.4
    }, {
        name: "Wineport",
        x: 21,
        y: 21
    }

    ],
    aranks: [{
        x: 28.95,
        y: 19.3,

    }, {
        x: 30.4,
        y: 19.3,

    }, {
        x: 31.05,
        y: 19.95,

    }, {
        x: 25.55,
        y: 20.4,

    }, {
        x: 28.62,
        y: 20.65,

    }, {
        x: 29.7,
        y: 21.4,

    }, {
        x: 25.4,
        y: 21.8,

    }, {
        x: 20.3,
        y: 25.2,

    }, {
        x: 22.25,
        y: 25.2,

    }, {
        x: 18.3,
        y: 26.2,

    }, {
        x: 17.9,
        y: 27.5,

    }, {
        x: 21.45,
        y: 28.3,

    }, {
        x: 14.75,
        y: 28.85,

    }, {
        x: 21.3,
        y: 29.6,

    }, {
        x: 17.4,
        y: 29.95,

    }, {
        x: 15,
        y: 30.6,

    }, {
        x: 21.3,
        y: 31.05,

    }, {
        x: 19.55,
        y: 31.35,

    }, {
        x: 16.5,
        y: 32.7,

    }, {
        x: 20.5,
        y: 33.15,

    }]
}, {
    key: 'wln',
    name: "wln",
    count: 23,
    fullname: "Western La Noscea",
    aetherytes: [{
        name: "Swiftperch",
        x: 34.5,
        y: 31.2
    }, {
        name: "Aleport",
        x: 26.6,
        y: 25.4
    }],
    arank1: "Nahn",
    aranks: [{
        x: 14.55,
        y: 14.25,

    }, {
        x: 16.1,
        y: 14.5,

    }, {
        x: 12.9,
        y: 14.55,

    }, {
        x: 14.2,
        y: 15.85,

    }, {
        x: 14.35,
        y: 17.05,

    }, {
        x: 20.2,
        y: 18.4,

    }, {
        x: 17.05,
        y: 19.4,

    }, {
        x: 19.6,
        y: 19.55,

    }, {
        x: 23.15,
        y: 21.75,

    }, {
        x: 19.85,
        y: 21.95,

    }, {
        x: 24.7,
        y: 22.7,

    }, {
        x: 26.1,
        y: 23.55,

    }, {
        x: 27.65,
        y: 24,

    }, {
        x: 27.65,
        y: 24,

    }, {
        x: 23.3,
        y: 24.65,

    }, {
        x: 29,
        y: 25.2,

    }, {
        x: 31.25,
        y: 27.4,

    }, {
        x: 34.05,
        y: 28.1,

    }, {
        x: 31.75,
        y: 28.7,

    }, {
        x: 30.8,
        y: 28.8,

    }, {
        x: 33.0,
        y: 29.8,

    }, {
        x: 16.15,
        y: 34.4,

    }, {
        x: 13.95,
        y: 35.15,

    }, {
        x: 16.95,
        y: 36.05,

    }]
}, {
    key: 'uln',
    name: "uln",
    count: 17,
    fullname: "Upper La Noscea",
    arank1: "Marberry",
    aetherytes: [{
        name: "Camp Bronze Lake",
        x: 30.2,
        y: 22.8
    }],
    aranks: [{
        x: 27.7,
        y: 19.85,

    }, {
        x: 29.05,
        y: 21.2,

    }, {
        x: 28.2,
        y: 21.4,

    }, {
        x: 10.55,
        y: 21.45,

    }, {
        x: 12.15,
        y: 21.75,

    }, {
        x: 28.75,
        y: 22.25,

    }, {
        x: 11.6,
        y: 22.65,

    }, {
        x: 12.65,
        y: 23.55,

    }, {
        x: 35.2,
        y: 23.7,

    }, {
        x: 27.35,
        y: 23.75,

    }, {
        x: 29.45,
        y: 23.95,

    }, {
        x: 33.4,
        y: 24.1,

    }, {
        x: 30.45,
        y: 25.3,

    }, {
        x: 28.05,
        y: 25.6,

    }, {
        x: 12.7,
        y: 25.7,

    }, {
        x: 33.25,
        y: 25.8,

    }, {
        x: 13.85,
        y: 25.9,

    }]
}, {
    key: 'oln',
    name: "oln",
    count: 23,
    fullname: "Outer La Noscea",
    arank1: "Cornu",
    aetherytes: [{
        name: "Camp Overlook",
        x: 19.1,
        y: 16.7
    }],
    aranks: [{
        x: 27,
        y: 5.2,

    }, {
        x: 21.5,
        y: 6.85,

    }, {
        x: 22.45,
        y: 6.85,

    }, {
        x: 27.4,
        y: 7.25,

    }, {
        x: 24.55,
        y: 7.35,

    }, {
        x: 21.75,
        y: 8.35,

    }, {
        x: 23.65,
        y: 9.25,

    }, {
        x: 21.8,
        y: 9.5,

    }, {
        x: 21.9,
        y: 10.3,

    }, {
        x: 15.6,
        y: 10.35,

    }, {
        x: 15.4,
        y: 12.65,

    }, {
        x: 23,
        y: 13.2,

    }, {
        x: 14.1,
        y: 14.05,

    }, {
        x: 21.35,
        y: 14.6,

    }, {
        x: 15.25,
        y: 14.8,

    }, {
        x: 19,
        y: 15.1,

    }, {
        x: 22.9,
        y: 15.15,

    }, {
        x: 24.6,
        y: 16.15,

    }, {
        x: 13.55,
        y: 16.2,

    }, {
        x: 23,
        y: 16.2,

    }, {
        x: 14.7,
        y: 16.85,

    }, {
        x: 16.45,
        y: 17.8,

    }, {
        x: 15.1,
        y: 18.05,

    }]
}, {
    key: 'cs',
    name: "cs",
    count: 24,
    fullname: "Central Shroud",
    arank1: "Forneus",
    aetherytes: [{
        name: "Bentbranch Meadows",
        x: 21.7,
        y: 21.7
    }],
    aranks: [{
        x: 28.75,
        y: 14.45,

    }, {
        x: 21.75,
        y: 16.35,

    }, {
        x: 9.55,
        y: 16.9,

    }, {
        x: 16.65,
        y: 17.8,

    }, {
        x: 19.1,
        y: 18.3,

    }, {
        x: 31.1,
        y: 18.6,

    }, {
        x: 10.05,
        y: 18.9,

    }, {
        x: 12.1,
        y: 19.4,

    }, {
        x: 29.1,
        y: 19.45,

    }, {
        x: 14.25,
        y: 19.5,

    }, {
        x: 14.25,
        y: 19.5,

    }, {
        x: 15.8,
        y: 19.65,

    }, {
        x: 26.75,
        y: 20.6,

    }, {
        x: 12.75,
        y: 20.75,

    }, {
        x: 16.05,
        y: 21.15,

    }, {
        x: 10.45,
        y: 21.7,

    }, {
        x: 28.25,
        y: 22.05,

    }, {
        x: 27.05,
        y: 22.6,

    }, {
        x: 29.65,
        y: 23.35,

    }, {
        x: 11.6,
        y: 23.55,

    }, {
        x: 15.85,
        y: 24,

    }, {
        x: 22.05,
        y: 24.5,

    }, {
        x: 23.4,
        y: 27.5,

    }, {
        x: 21.6,
        y: 30.05,

    }]

}, {
    key: 'es',
    name: "es",
    fullname: "East Shroud",
    count: 34,
    arank1: "Melt",
    aetherytes: [{
        name: "The Hawthorne Hut",
        x: 17.7,
        y: 27
    }],
    aranks: [{
        x: 25.05,
        y: 9.8,

    }, {
        x: 26.5,
        y: 11.3,

    }, {
        x: 29.5,
        y: 11.45,

    }, {
        x: 24.45,
        y: 11.7,

    }, {
        x: 28.1,
        y: 12.75,

    }, {
        x: 25.9,
        y: 13.1,

    }, {
        x: 29.9,
        y: 13.3,

    }, {
        x: 24.3,
        y: 15.2,

    }, {
        x: 31.9,
        y: 15.4,

    }, {
        x: 26.7,
        y: 16.05,

    }, {
        x: 24.0,
        y: 16.5,

    }, {
        x: 24.8,
        y: 17.4,

    }, {
        x: 30.3,
        y: 17.45,

    }, {
        x: 27.0,
        y: 17.95,

    }, {
        x: 27.8,
        y: 18.7,

    }, {
        x: 25.7,
        y: 20.35,

    }, {
        x: 29.0,
        y: 20.5,

    }, {
        x: 31.7,
        y: 21.5,

    }, {
        x: 16.85,
        y: 21.75,

    }, {
        x: 23.0,
        y: 21.75,

    }, {
        x: 21.25,
        y: 21.8,

    }, {
        x: 27.6,
        y: 22.6,

    }, {
        x: 17.4,
        y: 22.9,

    }, {
        x: 13.6,
        y: 23.25,

    }, {
        x: 25.3,
        y: 23.65,

    }, {
        x: 15.6,
        y: 23.9,

    }, {
        x: 27.7,
        y: 24.6,

    }, {
        x: 25.8,
        y: 25.2,

    }, {
        x: 14.35,
        y: 25.35,

    }, {
        x: 19.05,
        y: 25.4,

    }, {
        x: 14.5,
        y: 27.65,

    }, {
        x: 22.0,
        y: 28.1,

    }, {
        x: 19.95,
        y: 28.75,

    }, {
        x: 22.1,
        y: 29.6,

    }, {
        x: 23.8,
        y: 30.85,

    }]
}, {
    key: 'ss',
    name: "ss",
    count: 28,
    fullname: "South Shroud",
    arank1: "Ghede Ti Malice",
    aetherytes: [{
        name: "Quarrymill",
        x: 25.1,
        y: 19.6
    }, {
        name: "Camp Tranquil",
        x: 16.8,
        y: 28
    }],
    aranks: [{
        x: 27.75,
        y: 17.65,

    }, {
        x: 24.35,
        y: 18.2,

    }, {
        x: 16.9,
        y: 18.4,

    }, {
        x: 18.8,
        y: 18.45,

    }, {
        x: 22.15,
        y: 19.25,

    }, {
        x: 15.8,
        y: 19.7,

    }, {
        x: 26.95,
        y: 19.9,

    }, {
        x: 17.0,
        y: 21.45,

    }, {
        x: 21.4,
        y: 21.6,

    }, {
        x: 18.6,
        y: 22.05,

    }, {
        x: 22.65,
        y: 22.65,

    }, {
        x: 27.05,
        y: 22.75,

    }, {
        x: 16.9,
        y: 23.65,

    }, {
        x: 31.7,
        y: 23.75,

    }, {
        x: 32.7,
        y: 23.95,

    }, {
        x: 23.0,
        y: 24.15,

    }, {
        x: 28.85,
        y: 24.65,

    }, {
        x: 30.55,
        y: 25.6,

    }, {
        x: 22.65,
        y: 25.65,

    }, {
        x: 19.4,
        y: 27.4,

    }, {
        x: 16.15,
        y: 27.6,

    }, {
        x: 21.4,
        y: 27.65,

    }, {
        x: 15.7,
        y: 28.55,

    }, {
        x: 19.0,
        y: 28.65,

    }, {
        x: 17.25,
        y: 30.5,

    }, {
        x: 18.25,
        y: 30.9,

    }, {
        x: 16.3,
        y: 32.05,

    }, {
        x: 17.15,
        y: 32.45,

    }]
}, {
    key: 'ns',
    name: "ns",
    count: 20,
    fullname: "North Shroud",
    arank1: "Girtab",
    aetherytes: [{
        name: "Fallgourd Float",
        x: 20.5,
        y: 25.7
    }],
    aranks: [{
        x: 23.45,
        y: 19.95,

    }, {
        x: 24.45,
        y: 20.2,

    }, {
        x: 18.65,
        y: 20.4,

    }, {
        x: 22.65,
        y: 20.7,

    }, {
        x: 28.6,
        y: 21.65,

    }, {
        x: 27.65,
        y: 22.25,

    }, {
        x: 25.95,
        y: 22.75,

    }, {
        x: 22.15,
        y: 23.75,

    }, {
        x: 27.95,
        y: 24.3,

    }, {
        x: 16.65,
        y: 25.4,

    }, {
        x: 24.8,
        y: 25.7,

    }, {
        x: 25.8,
        y: 26.05,

    }, {
        x: 18.2,
        y: 27.05,

    }, {
        x: 27.05,
        y: 27.2,

    }, {
        x: 22.45,
        y: 28.15,

    }, {
        x: 15.95,
        y: 28.25,

    }, {
        x: 24.55,
        y: 28.3,

    }, {
        x: 19.4,
        y: 28.6,

    }, {
        x: 18.15,
        y: 28.95,

    }, {
        x: 21.55,
        y: 30.05,

    }]
}, {
    key: 'wt',
    name: "wt",
    count: 22,
    fullname: "Western Thanalan",
    aetherytes: [{
        name: "Horizon",
        x: 22.8,
        y: 16.4
    }],
    arank1: "Alectryon",
    aranks: [{
        x: 10.35,
        y: 5.25,

    }, {
        x: 8.45,
        y: 5.5,

    }, {
        x: 12.15,
        y: 5.95,

    }, {
        x: 14.95,
        y: 6,

    }, {
        x: 10.2,
        y: 6.4,

    }, {
        x: 12,
        y: 7.5,

    }, {
        x: 14.05,
        y: 7.85,

    }, {
        x: 18.3,
        y: 15,

    }, {
        x: 16.95,
        y: 16.45,

    }, {
        x: 26.85,
        y: 16.95,

    }, {
        x: 18.3,
        y: 17.4,

    }, {
        x: 26.2,
        y: 19.15,

    }, {
        x: 22.85,
        y: 20.1,

    }, {
        x: 21.95,
        y: 22.05,

    }, {
        x: 21.25,
        y: 24,

    }, {
        x: 22.3,
        y: 24,

    }, {
        x: 22.75,
        y: 24.75,

    }, {
        x: 20.65,
        y: 25.6,

    }, {
        x: 26.1,
        y: 26.35,

    }, {
        x: 21.3,
        y: 27.8,

    }, {
        x: 18.9,
        y: 29.35,

    }, {
        x: 20.3,
        y: 29.35,

    }]
}, {
    key: 'ct',
    name: "ct",
    count: 23,
    fullname: "Central Thanalan",
    arank1: "Sabotender Bailarina",
    aetherytes: [{
        name: "Black Brush Station",
        x: 21.1,
        y: 17.7
    }],
    aranks: [{
        x: 16.3,
        y: 12.4,

    }, {
        x: 18,
        y: 13.5,

    }, {
        x: 21.75,
        y: 13.65,

    }, {
        x: 21.9,
        y: 15.55,

    }, {
        x: 16.6,
        y: 16.4,

    }, {
        x: 20.8,
        y: 16.4,

    }, {
        x: 18.1,
        y: 17.5,

    }, {
        x: 25.4,
        y: 17.95,

    }, {
        x: 27.75,
        y: 18,

    }, {
        x: 17.9,
        y: 18.9,

    }, {
        x: 16.3,
        y: 19.55,

    }, {
        x: 29.5,
        y: 20.9,

    }, {
        x: 21.6,
        y: 21.1,

    }, {
        x: 27.2,
        y: 21.5,

    }, {
        x: 19.2,
        y: 21.7,

    }, {
        x: 16.95,
        y: 23.3,

    }, {
        x: 19.45,
        y: 23.7,

    }, {
        x: 18.7,
        y: 25.25,

    }, {
        x: 20.45,
        y: 26.1,

    }, {
        x: 25.8,
        y: 29.85,

    }, {
        x: 22.4,
        y: 31.4,

    }, {
        x: 23.9,
        y: 31.9,

    }, {
        x: 23.7,
        y: 35,

    }]
}, {
    key: 'et',
    name: "et",
    count: 34,
    fullname: "Eastern Thanalan",
    arank1: "Maahes",
    aetherytes: [{
        name: "Camp Drybone",
        x: 13.7,
        y: 23.8
    }],
    aranks: [{
        x: 12.8,
        y: 15.85,

    }, {
        x: 14.5,
        y: 16.25,

    }, {
        x: 29.6,
        y: 17.3,

    }, {
        x: 29.6,
        y: 17.3,

    }, {
        x: 25.8,
        y: 17.65,

    }, {
        x: 27.35,
        y: 17.9,

    }, {
        x: 14.65,
        y: 18.7,

    }, {
        x: 12.7,
        y: 18.9,

    }, {
        x: 24.3,
        y: 18.95,

    }, {
        x: 10.4,
        y: 19.1,

    }, {
        x: 26.7,
        y: 19.25,

    }, {
        x: 22.8,
        y: 19.65,

    }, {
        x: 17.4,
        y: 20,

    }, {
        x: 15.65,
        y: 20.15,

    }, {
        x: 23.95,
        y: 21.25,

    }, {
        x: 28.1,
        y: 21.3,

    }, {
        x: 17.5,
        y: 21.4,

    }, {
        x: 18.95,
        y: 22.5,

    }, {
        x: 23.45,
        y: 22.85,

    }, {
        x: 25,
        y: 23.25,

    }, {
        x: 19.85,
        y: 24.35,

    }, {
        x: 17.4,
        y: 24.75,

    }, {
        x: 26.7,
        y: 25.05,

    }, {
        x: 17.85,
        y: 25.5,

    }, {
        x: 25.4,
        y: 25.55,

    }, {
        x: 27.9,
        y: 25.75,

    }, {
        x: 16.6,
        y: 25.9,

    }, {
        x: 14.55,
        y: 26.15,

    }, {
        x: 23.4,
        y: 26.7,

    }, {
        x: 29.3,
        y: 26.75,

    }, {
        x: 15.4,
        y: 26.9,

    }, {
        x: 17.45,
        y: 28,

    }, {
        x: 19.55,
        y: 28.45,

    }, {
        x: 20.35,
        y: 28.55,

    }, {
        x: 12.9,
        y: 29,

    }]
}, {
    key: 'st',
    name: "st",
    count: 42,
    fullname: "Southern Thanalan",
    arank1: "Zanig'oh",
    aetherytes: [{
        name: "Little Ala Mhigo",
        x: 18.2,
        y: 12.7
    }, {
        name: "Forgotten Springs",
        x: 14.9,
        y: 29.1
    }],
    aranks: [{
        x: 23.2,
        y: 7.95,

    }, {
        x: 24.65,
        y: 9.35,

    }, {
        x: 18,
        y: 10.2,

    }, {
        x: 21.25,
        y: 10.85,

    }, {
        x: 18.25,
        y: 11.2,

    }, {
        x: 24.65,
        y: 11.45,

    }, {
        x: 17.2,
        y: 11.7,

    }, {
        x: 23.8,
        y: 12.45,

    }, {
        x: 17.2,
        y: 16.95,

    }, {
        x: 19.55,
        y: 17.2,

    }, {
        x: 31,
        y: 18.4,

    }, {
        x: 19.7,
        y: 19.25,

    }, {
        x: 32.9,
        y: 19.85,

    }, {
        x: 15.95,
        y: 20.25,

    }, {
        x: 21.4,
        y: 20.75,

    }, {
        x: 28.5,
        y: 20.75,

    }, {
        x: 18.35,
        y: 21.2,

    }, {
        x: 24.15,
        y: 21.3,

    }, {
        x: 18.45,
        y: 22.9,

    }, {
        x: 21.05,
        y: 22.9,

    }, {
        x: 16.65,
        y: 23.15,

    }, {
        x: 16,
        y: 24.45,

    }, {
        x: 19.8,
        y: 24.7,

    }, {
        x: 24.4,
        y: 24.85,

    }, {
        x: 17.05,
        y: 25.7,

    }, {
        x: 21.15,
        y: 26.35,

    }, {
        x: 20.25,
        y: 28.5,

    }, {
        x: 21.6,
        y: 29.9,

    }, {
        x: 23.85,
        y: 30.4,

    }, {
        x: 18.85,
        y: 30.55,

    }, {
        x: 21.2,
        y: 32.15,

    }, {
        x: 16.85,
        y: 33.05,

    }, {
        x: 23.45,
        y: 33.3,

    }, {
        x: 21.8,
        y: 34.3,

    }, {
        x: 28.5,
        y: 34.7,

    }, {
        x: 13.8,
        y: 34.85,

    }, {
        x: 16.1,
        y: 36.8,

    }, {
        x: 24.45,
        y: 36.9,

    }, {
        x: 19.1,
        y: 38.4,

    }, {
        x: 22.5,
        y: 38.65,

    }, {
        x: 14.75,
        y: 38.85,

    }, {
        x: 25.1,
        y: 40.85,
    }]
}, {
    key: 'nt',
    name: "nt",
    count: 17,
    fullname: "Northern Thanalan",
    arank1: "Dalvag's Final Flame",
    aetherytes: [{
        name: "Ceruleum Processing Plant",
        x: 21,
        y: 20.5
    }, {
        name: "Camp Bluefog",
        x: 21.9,
        y: 30.1
    }],
    aranks: [{
        x: 16.05,
        y: 14.65,

    }, {
        x: 17.25,
        y: 15.7,

    }, {
        x: 16.05,
        y: 15.95,

    }, {
        x: 19.05,
        y: 16.75,

    }, {
        x: 16.85,
        y: 16.95,

    }, {
        x: 18.25,
        y: 17.5,

    }, {
        x: 18.75,
        y: 18,

    }, {
        x: 15.4,
        y: 18.55,

    }, {
        x: 16.35,
        y: 19.4,

    }, {
        x: 23.95,
        y: 22.85,

    }, {
        x: 24.3,
        y: 23.2,

    }, {
        x: 23.65,
        y: 24.15,

    }, {
        x: 21.05,
        y: 25,

    }, {
        x: 23.6,
        y: 25.25,

    }, {
        x: 23.45,
        y: 26.3,

    }, {
        x: 22.2,
        y: 27,

    }, {
        x: 20.6,
        y: 27.8,

    }]
}, {
    key: 'md',
    name: "md",
    count: 30,
    instances: 1,
    fullname: "Mor Dhona",
    arank1: "Kurrea",
    aetherytes: [{
        name: "Revenant's Toll",
        x: 22.3,
        y: 7.7
    }],
    aranks: [{
        x: 30.6,
        y: 6.4,
    }, {
        x: 29.1,
        y: 6.5,

    }, {
        x: 32.3,
        y: 6.9,

    }, {
        x: 29.1,
        y: 7.7,

    }, {
        x: 19.15,
        y: 8.1,

    }, {
        x: 26.8,
        y: 8.5,

    }, {
        x: 32.45,
        y: 8.6,

    }, {
        x: 27.15,
        y: 8.85,

    }, {
        x: 32.3,
        y: 10.2,

    }, {
        x: 16.05,
        y: 10.45,

    }, {
        x: 14.0,
        y: 10.5,

    }, {
        x: 27.4,
        y: 10.6,

    }, {
        x: 24.85,
        y: 10.65,

    }, {
        x: 23.2,
        y: 10.7,

    }, {
        x: 31.45,
        y: 11.3,

    }, {
        x: 11.9,
        y: 11.8,

    }, {
        x: 15.75,
        y: 11.8,

    }, {
        x: 33.6,
        y: 12.05,

    }, {
        x: 13.65,
        y: 12.15,

    }, {
        x: 23.4,
        y: 12.2,

    }, {
        x: 25.2,
        y: 12.7,

    }, {
        x: 26.8,
        y: 12.7,

    }, {
        x: 28.25,
        y: 13.55,

    }, {
        x: 12.7,
        y: 13.75,

    }, {
        x: 14.2,
        y: 13.85,

    }, {
        x: 9.8,
        y: 13.9,

    }, {
        x: 32.4,
        y: 14.35,

    }, {
        x: 28.95,
        y: 14.75,

    }, {
        x: 30.15,
        y: 15.05,

    }, {
        x: 10.6,
        y: 15.15,

    }, {
        x: 16.4,
        y: 15.6,

    }, {
        x: 12.6,
        y: 16.0,

    }, {
        x: 12.25,
        y: 16.85,

    }, {
        x: 16.8,
        y: 17.4,

    }]

}, {
    key: 'cch',
    name: "cch",
    count: 60,
    fullname: "Coerthas Central Highlands",
    aetherytes: [{
        name: "Camp Dragonhead",
        x: 25.9,
        y: 16.2
    }],
    arank1: "Marraco",
    aranks: [{
        x: 32.05,
        y: 7.1,

    }, {
        x: 28.9,
        y: 7.75,

    }, {
        x: 22.95,
        y: 8.05,

    }, {
        x: 24.65,
        y: 9.1,

    }, {
        x: 30.15,
        y: 10.6,

    }, {
        x: 25.3,
        y: 11.2,

    }, {
        x: 28.1,
        y: 11.45,

    }, {
        x: 8.95,
        y: 11.9,

    }, {
        x: 30.2,
        y: 12.6,

    }, {
        x: 10.8,
        y: 12.85,

    }, {
        x: 27.05,
        y: 13.45,

    }, {
        x: 8.85,
        y: 13.5,

    }, {
        x: 24.8,
        y: 13.5,

    }, {
        x: 11.75,
        y: 14.25,

    }, {
        x: 31.5,
        y: 14.3,

    }, {
        x: 8.85,
        y: 14.8,

    }, {
        x: 28.4,
        y: 14.85,

    }, {
        x: 35.05,
        y: 15.3,

    }, {
        x: 5.25,
        y: 16.0,

    }, {
        x: 20.55,
        y: 16.25,

    }, {
        x: 4.15,
        y: 16.6,

    }, {
        x: 31.9,
        y: 16.95,

    }, {
        x: 5.6,
        y: 18.05,

    }, {
        x: 14.3,
        y: 18.05,

    }, {
        x: 10.5,
        y: 18.25,

    }, {
        x: 15.4,
        y: 18.5,

    }, {
        x: 19.55,
        y: 18.65,

    }, {
        x: 21.55,
        y: 18.8,

    }, {
        x: 17.5,
        y: 18.85,

    }, {
        x: 11.55,
        y: 19.65,

    }, {
        x: 14.3,
        y: 19.8,

    }, {
        x: 6.45,
        y: 19.85,

    }, {
        x: 24.6,
        y: 20.0,

    }, {
        x: 16.85,
        y: 20.5,

    }, {
        x: 27.1,
        y: 20.65,

    }, {
        x: 8.35,
        y: 21.0,

    }, {
        x: 24.15,
        y: 21.7,

    }, {
        x: 27.4,
        y: 22.15,

    }, {
        x: 34.8,
        y: 22.75,

    }, {
        x: 35.8,
        y: 23.05,

    }, {
        x: 32.8,
        y: 23.6,

    }, {
        x: 24.65,
        y: 23.75,

    }, {
        x: 13.4,
        y: 25.25,

    }, {
        x: 14.9,
        y: 25.6,

    }, {
        x: 28.1,
        y: 25.6,

    }, {
        x: 25.65,
        y: 25.65,

    }, {
        x: 23.45,
        y: 26.8,

    }, {
        x: 9.0,
        y: 27.25,

    }, {
        x: 11.0,
        y: 27.3,

    }, {
        x: 15.8,
        y: 27.45,

    }, {
        x: 29.3,
        y: 27.65,

    }, {
        x: 12.65,
        y: 28.05,

    }, {
        x: 21.4,
        y: 28.2,

    }, {
        x: 6.6,
        y: 28.7,

    }, {
        x: 15.5,
        y: 28.7,

    }, {
        x: 11.05,
        y: 28.95,

    }, {
        x: 19.8,
        y: 29.2,

    }, {
        x: 17.7,
        y: 29.35,

    }, {
        x: 30.3,
        y: 29.8,

    }, {
        x: 30.75,
        y: 31.05,

    }]

}]


exports.ArrMap = ArrMap