import {setDatabaseCode} from "../state-objects/DatabaseCodeSlice";
import CallFetchAPI from "./CallFetchAPI";

export default function WhenToggleDatabaseOn(paramsCode, scoutingMarks, databaseOn, databaseCode, dispatch) {
    if (!databaseOn) return

    if (databaseCode === undefined && paramsCode !== undefined && !paramsCode.includes(">")) {
        dispatch(setDatabaseCode(paramsCode))
    }

    if (databaseCode === undefined) {
        CallFetchAPI(scoutingMarks, databaseOn, databaseCode, dispatch).then()
    }
}