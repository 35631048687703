import React from "react";
import {useSelector} from "react-redux";

function MapTitle(props) {
    const rows = useSelector((state) => state.rows.value)


    function createStyle() {
        return {
            fontSize: "min(48px, " + 5 / rows + "vw)",
            display: "compact",
            textAlign: "right",
            color: "#da5db6",
            position: "absolute",
            right: "0px",
            marginRight: "min(10px, 1vw)",
            marginBlockStart: "0.1em",
            marginBlockEnd: "0.1em",
            fontFamily: "\"Segoe UI\", Arial, sans-serif",
            padding: 0,
            zIndex: 1,
            textShadow: "-1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000, 1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000"
        }
    }

    let instance = ' ' + props.instance
    if (props.instanceCount === 1) instance = ''

    return (
        <h2 key={props.name + props.instance + "h2"} style={createStyle()}>
            {props.name + instance}
        </h2>)
}

export default MapTitle