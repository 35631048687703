import ExpansionMap from "../data/ExpansionData";
import SortMaps from "./SortMaps";
import BuildMaps from "./BuildMaps";
import {useSelector} from "react-redux";
import React from "react";

function OutputExpansions() {

    const expansions = useSelector((state) => state.expansions.value)
    const sortType = useSelector((state) => state.sortType.value)

    let divs = []

    for (let expansion in ExpansionMap) {
        if (!expansions.includes(expansion)) continue

        let map = [...ExpansionMap[expansion].map]

        let spotData = {}
        spotData["expansion"] = expansion

        map = SortMaps(sortType, expansion)


        let result = map.map(mapData =>
            <BuildMaps key={expansion + mapData.key + "-buildmaps"} mapData={mapData} spotData={spotData}/>
        )

        divs.push(result)
    }
    return divs
}


export default OutputExpansions