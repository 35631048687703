export default async function CallChangeAPI(json, databaseOn, databaseCode) {

    if (!databaseOn || databaseCode === undefined) return

    let api = process.env.REACT_APP_API_URL

    if (api === undefined) api = "https://api.sirenhunts.com"
    const scoutDataAPI = api + "/change"

    if (!json.startsWith("[")) json = "[" + json + "]"

    let codeFinal = "/" + databaseCode;

    try {
        await fetch(scoutDataAPI + codeFinal, {
            method: 'POST',
            body: json, // string or object
            headers: {
                'Content-Type': 'application/json'
            }
        });
    } catch (e) {
        console.log("Database connection is offline.", e)
    }
}