import {DTMap} from "./DtCoords";
import {EWMap} from "./EwCoords";
import {SHBMap} from "./ShbCoords";
import {STBMap} from "./StbCoords";
import {HWMap} from "./HwCoords";
import {ArrMap} from "./ArrCoords";

const ExpansionMap = {
    "arr": {
        key: "arr",
        name: "A Realm Reborn",
        shortName: "ARR",
        number: "2.0",
        imgType: ".jpg",
        multiplier: 1,
        index: 0,
        width: 42,
        map: ArrMap
    },
    "hw": {
        key: "hw",
        name: "Heavensward",
        shortName: "HW",
        number: "3.0",
        imgType: ".jpg",
        multiplier: 2,
        width: 44,
        index: 1,
        map: HWMap
    },
    "stb": {
        key: "stb",
        name: "Stormblood",
        shortName: "StB",
        number: "4.0",
        imgType: ".jpg",
        multiplier: 2,
        index: 2,
        width: 42,
        map: STBMap
    },
    "shb": {
        key: "shb",
        name: "Shadowbringers",
        shortName: "ShB",
        number: "5.0",
        imgType: ".jpg",
        multiplier: 2,
        index: 3,
        width: 42,
        map: SHBMap

    },
    "ew": {
        key: "ew",
        name: "Endwalker",
        shortName: "EW",
        number: "6.0",
        imgType: ".jpg",
        multiplier: 2,
        index: 4,
        width: 42,
        map: EWMap
    },
    "dt": {
        key: "dt",
        name: "Dawntrail",
        shortName: "DT",
        number: "7.0",
        imgType: ".png",
        multiplier: 2,
        width: 42,
        index: 5,
        map: DTMap
    }
}

export default ExpansionMap