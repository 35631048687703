import CharacterData from "./CharacterData";
import * as React from 'react';
import Grid from '@mui/material/Grid';


export default function CharacterCard(props) {


    function createCards() {
        let items = []

        function characterImage(value) {
            return <>
                <img src={require("../content/" + value.photo.location)} className="char-photo"
                     alt={value.name}/>
                <div className={"grid-text grid-" + value.photo.align}>
                    <p>{value.name}</p>
                    <p>{value.nick}</p>
                    <p>{value.pronouns}</p>
                </div>
                <div className={"grid-roles-text grid-roles-" + value.photo.align.split("-")[0]}>
                    <p>{value.roles}</p>
                </div>
            </>;
        }

        function characterText(value) {
            return <>
                <div className={"grid-about"}>
                    <b>Hunting Since</b>
                    <p>{value.since}</p>

                    <b>About</b>
                    <p>{value.about}</p>
                </div>
            </>;
        }

        function doClick(value) {
            props.updateCards(value.name);
        }

        for (let key in CharacterData) {
            let value = CharacterData[key];

            items.push(
                <Grid item xs={3}>
                    <div className="grid-card" onClick={() => doClick(value)}>
                        {props.characterCards.includes(value.name) ? characterText(value) : characterImage(value)}
                    </div>
                </Grid>
            )
        }

        return items;
    }

    return (
        <>
            {createCards()}
        </>
    )
}