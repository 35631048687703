import React from 'react';
import {FaDiscord, FaHome, FaMapMarkedAlt} from "react-icons/all";
import Navbar from 'react-bootstrap/Navbar';
import {Container} from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css'
import {Button, ButtonGroup} from "@mui/material";
import {Link} from 'react-router-dom';


function sirenNavigation() {

    const tabs = [
        {
            route: "/home",
            icon: <FaHome className="siren-menu-icon "/>,
            label: "Home",
            key: '001'
        },
        {
            route: "/scouting",
            icon: <FaMapMarkedAlt className="siren-menu-icon"/>,
            label: "Scouting Tool",
            key: '002'
        },
        {
            link: "https://discord.gg/siren-hunts",
            icon: <FaDiscord className="siren-menu-icon"/>,
            label: "Discord",
            key: '003'
        }
    ]

    return (
        <Navbar bg="light" expand="lg" className="siren-top-level-navbar">
            <Container>
                <Navbar.Brand href="#">
                    <img src={require("../content/logo.png")} className="logo-siren" alt="React Bootstrap logo"/>
                </Navbar.Brand>
                <Nav className="me-auto">
                    <ButtonGroup color="secondary"
                                 aria-label="primary button group">
                        {tabs.map(item => {
                            if (item.route !== undefined)
                                return <Button key={"nav-link-" + item.key} variant={window.location.pathname.includes(item.route)? "contained" : "outlined"} component={Link} to={item.route}
                                        startIcon={item.icon}>{item.label}</Button>
                            else return <Button key={"nav-link-" + item.key} href={item.link}
                                                startIcon={item.icon}>{item.label}</Button>
                            }
                        )}
                    </ButtonGroup>
                </Nav>

            </Container>
        </Navbar>
    )
}

export default sirenNavigation;