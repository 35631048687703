import {Button, Snackbar} from "@mui/material";
import React from "react";
import MuiAlert from "@mui/material/Alert";
import {TbFileExport} from "react-icons/all";
import Tooltip from "@mui/material/Tooltip";


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ScoutsToTextItem(props) {

    const [openAlert, setOpenAlert] = React.useState(false)

    const id = openAlert ? 'simple-popover' : undefined;

    const handleCloseAlert = () => {
        setOpenAlert(false)
    };

    function onClick() {
        props.clipboard()
        setOpenAlert(true)
    }

    return (
        <>
            <Tooltip title="Copy Scouts to Text Form" placement="top" arrow>
                <Button
                    id="scouts-to-text-button"
                    size="small"
                    onClick={onClick} color="secondary" startIcon={<TbFileExport/>}>
                    Text
                </Button>
            </Tooltip>

            <Snackbar open={openAlert}
                      id={id}
                      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                      autoHideDuration={5000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert}
                       severity="info" sx={{width: '100%'}}>
                    Scouts copied to clipboard!
                </Alert>
            </Snackbar>
        </>

    )
}