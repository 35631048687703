import {createSlice} from '@reduxjs/toolkit'

export const SortTypeSlice = createSlice({
    name: 'sortType',
    initialState: {
        value: "DEFAULT",
    },
    reducers: {
        setSortType: (state, action) => {
            state.value = action.payload
        }
    },
})

export const { setSortType } = SortTypeSlice.actions

export default SortTypeSlice.reducer