function convertNumberToLetter(num, double) {
    if (num === -1 && double) return "--"
    else if (num === -1) return "-"

    let first = num+1 % 26
    let numDif = num+1 - first - 26 + 1

    if (first <= 0) {
        first = 26 - first;
        numDif = 0
    }

    let letter = String.fromCharCode(first + 64)

    if (double) {
        if (numDif > 0) letter = letter + String.fromCharCode(numDif + 64)
        else letter = letter + "-"
    }

    return letter;
}


function HashCreator(mapMarkData, double) {
    let first = -1;
    let second = -1;

    for (let idx in mapMarkData) {
        let mark = mapMarkData[idx]
        if (mark["status"] === "mark1") first = Number(mark["index"])
        else second = Number(mark["index"])
    }

    return convertNumberToLetter(first, double) + convertNumberToLetter(second, double)
}

export default HashCreator;