import React, {useEffect} from 'react';
import '../Pages/Home.css';
import SirenNavigation from "../Pages/SirenNavigation";
import './Scouting.css';
import {useNavigate, useParams} from 'react-router-dom';
import LoadScoutingData from "./map-building/LoadScoutingData";
import OutputExpansions from "./map-building/OutputExpansions";
import {useDispatch, useSelector} from "react-redux";
import CallFetchAPI from "./database/CallFetchAPI";
import WhenToggleDatabaseOn from "./database/WhenToggleDatabaseOn";
import {setExpansions} from "./state-objects/ExpansionsSlice";
import WhenToggleDatabaseCode from "./database/WhenToggleDatabaseCode";
import {setDatabaseOn} from "./state-objects/DatabaseOnSlice";
import {useInterval} from "./util/useInterval";
import {setDatabaseCode} from "./state-objects/DatabaseCodeSlice";
import ScoutingTopMenu from "./ScoutingTopMenu";
import {MapRowStyle} from "./ScoutsStyles";
import ExpansionMap from "./data/ExpansionData";
import {formatMapName} from "./util/Utility";
import {setScouted} from "./state-objects/PageMetaSlice";


function Scouting() {

    const scoutingMarks = useSelector((state) => state.scoutingMarks.value)
    const loaded = useSelector((state) => state.loaded.value)
    const rows = useSelector((state) => state.rows.value)
    const databaseOn = useSelector((state) => state.databaseOn.value)
    const databaseCode = useSelector((state) => state.databaseCode.value)
    const maxARanks = useSelector((state) => state.pageMeta.max_count)
    const scoutedARanks = useSelector((state) => state.pageMeta.scouted_count)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const INTERVAL = 5 * 1000

    let params = useParams();

    useInterval(() => {
        CallFetchAPI(scoutingMarks, databaseOn, databaseCode, dispatch).then()
    }, INTERVAL)


    useEffect(() => {
        WhenToggleDatabaseOn(params.code, scoutingMarks, databaseOn, databaseCode, dispatch)
    }, [databaseOn])

    useEffect(() => {
        WhenToggleDatabaseCode(scoutingMarks, databaseOn, databaseCode, navigate, dispatch)
    }, [databaseCode])

    useEffect(() => {
        if (params.code !== undefined && params.code.length === 8) {
            dispatch(setDatabaseOn(true))
            dispatch(setDatabaseCode(params.code))
        }
    }, []);

    useEffect(() => {
        for (let expansion in ExpansionMap) {
            let expansionData = ExpansionMap[expansion]
            let map = expansionData.map

            let scoutedARanks = 0
            for (let mapIdx in map) {
                let mapData = map[mapIdx]
                let instances = mapData.instances === undefined ? 1 : mapData.instances;

                for (let i = 1; i < instances + 1; i++) {
                    let instancedMap = scoutingMarks[formatMapName(expansion, mapData.name, i)]
                    for (let spot_idx in instancedMap) {
                        let spot = instancedMap[spot_idx]
                        if (spot["status"] !== "remove") {
                            scoutedARanks += 1
                        }
                    }
                }
            }

            dispatch(setScouted({
                expansion_index: ExpansionMap[expansion].index,
                value: scoutedARanks
            }))
        }
    }, [scoutingMarks])

    if (params.code !== undefined && params.code.includes(">") && !loaded) {
        let expans = []
        let val = params.code.split("&")
        for (let idx in val) {
            let exp = LoadScoutingData(val[idx], scoutingMarks, databaseOn, databaseCode, dispatch)
            if (exp === undefined) continue
            expans.push(exp)
        }
        dispatch(setExpansions(expans))
    }

    const aRanks = () => {
        let desc = ""
        let expansionList = []
        for (let exp in ExpansionMap) {
            expansionList.push(exp)
        }
        for (let idx in scoutedARanks) {
            let scouted = scoutedARanks[idx]
            let expansionMetadata = ExpansionMap[expansionList[idx]]
            if (scouted > 0) {
                desc += expansionMetadata.shortName + ": " + scouted + "/" + maxARanks[idx] + " "
            }
        }
        return desc
    }

    return (
        <div className="scouting">
            <meta name="og:title" content={aRanks()} />
            <SirenNavigation/>
            <ScoutingTopMenu/>
            <div className="container-maps" style={MapRowStyle(rows)}>
                <OutputExpansions />
            </div>
        </div>
    );

}

export default Scouting;