import {Button} from "@mui/material";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setDatabaseOn} from "../state-objects/DatabaseOnSlice";
import Tooltip from "@mui/material/Tooltip";

export default function DatabaseToggle() {

    const databaseOn = useSelector((state) => state.databaseOn.value)
    const dispatch = useDispatch()

    function handleClick() {
        dispatch(setDatabaseOn(!databaseOn))
    }

    function createToggle() {
        return (
            <Tooltip title="Turn on to use online editing mode. Share the link to edit with friends!"  placement="top" arrow>
                <Button size="small" color="secondary"
                            key={"databaseToggle"}
                        variant={databaseOn ? "contained" : "outlined"}
                        onClick={() => {handleClick()}}
                >Online</Button>
            </Tooltip>
        )
            ;
    }

    return (
        <>
            {createToggle()}
        </>
    )
}