import {setDatabaseCode} from "../state-objects/DatabaseCodeSlice";
import ToggleExpansionFromDataBase from "./ToggleExpansionFromDatabase";

export default async function CallFetchAPI(scoutingMarks, databaseOn, databaseCode, dispatch) {
    if (!databaseOn) return
    let api = process.env.REACT_APP_API_URL
    if (api === undefined) api = "https://api.sirenhunts.com"
    const scoutDataAPI = api + "/fetch"

    let codeFinal = databaseCode === undefined ? "/" : "/" + databaseCode;
    try {
        const response = await fetch(scoutDataAPI + codeFinal, {
            method: 'GET'
        });


        const expMap = await response.json()

        dispatch(setDatabaseCode(expMap.code))


        ToggleExpansionFromDataBase(expMap, scoutingMarks, databaseOn, databaseCode, dispatch)
    } catch (e) {
        console.log("Database connection is offline.", e)
    }


}