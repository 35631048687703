function HashDecoder(code, aRankCount) {

    function convertLetterToNumber(str) {
        if (str === "--" || str === "-") return -1
        let clean = str.replace("-", "")
        let out = 0, len = clean.length;
        for (let pos = 0; pos < len; pos++) {
            out += (clean.charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1);
        }
        return out - 1;
    }

    let double = code.length > aRankCount ? 1:2

    let chunks = [];

    let lenBlock = 4/double;

    for (let i = 0, charsLength = code.length; i < charsLength; i += lenBlock) {
        let chunk = code.substring(i, i + lenBlock);
        let chunk1 = chunk.substring(0, lenBlock/2)
        let chunk2 = chunk.substring(lenBlock/2, lenBlock);
        chunks.push(convertLetterToNumber(chunk1))
        chunks.push(convertLetterToNumber(chunk2))
    }
    return chunks.reverse();
}

export default HashDecoder;