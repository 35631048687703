import Tooltip from "@mui/material/Tooltip";
import {Box, Button, Typography} from "@mui/material";
import React from "react";
import {useSelector} from "react-redux";
import ExpansionMap from "../data/ExpansionData";

function ExpansionButton(props) {

    const expansions = useSelector((state) => state.expansions.value)
    const maxARanks = useSelector((state) => state.pageMeta.max_count)
    const scoutedARanks = useSelector((state) => state.pageMeta.scouted_count)

    let expansionMeta = ExpansionMap[props.expansion];

    let text = []
    text.push(<Typography key={expansionMeta.number + "-title1"} style={{
        marginTop: ".25em"
    }} variant={"body2"}>{expansionMeta.number}</Typography>)

    let expansionCount = scoutedARanks[expansionMeta.index]

    if (expansionCount > 0) {
        text.push(<Typography key={expansionMeta.number + "-title2"} variant={"caption"}>{expansionCount + "/" + maxARanks[ExpansionMap[props.expansion].index]}</Typography>)
    }

    return (
        <Tooltip title={expansionMeta.name} placement="top" key={"tooltip" + props.expansion} arrow>
            <Button style={{
                fontSize: "max(.6vw,0.5rem)",
                maxWidth: '40px',
                minHeight: '2vh',
                maxHeight: '40px',
                minWidth: '2vw'
            }} size="small" variant={expansions.includes(props.expansion) ? "contained" : "outlined"}
                    key={"exp" + props.expansion}
                    onClick={() => {
                        props.updateExpansion(props.expansion)
                    }}>
                <Box sx={{m: 0.5}}>
                    {text}
                </Box>
            </Button>
        </Tooltip>
    )
}

export default ExpansionButton