import {addScoutingMarks} from "../../state-objects/ScoutingMarksSlice";

export default function ToggleSpot(expansion, map, instance, spot_idx, mark_status, timeChange, scoutingMarks, databaseOn, databaseCode, dispatch) {
    if (spot_idx === undefined || spot_idx === -1 || mark_status === undefined || timeChange === undefined) return;

    let namedLocation = expansion + "-" + map + "-" + instance;
    let instancedMapScouts = scoutingMarks === undefined ? {} : scoutingMarks[namedLocation];
    let scoutData = {
        expansion: expansion,
        map: map,
        instance: instance,
        index: spot_idx,
        status: mark_status,
        timeStamp: timeChange
    }

    let oldMark = undefined

    for (let x in instancedMapScouts) {
        let o = instancedMapScouts[x]
        if (o["index"] === spot_idx) {
            oldMark = instancedMapScouts[x]
            break
        }
    }

    if (oldMark !== undefined) {
        if (oldMark["timeStamp"] > timeChange) return
    }

    dispatch(addScoutingMarks(scoutData))
}