const EWMap = [{
    key: 'laby',
    name: "laby",
    arank1: "Storsie",
    arank2: "Hulder",
    fullname: "Labyrinthos",
    aetherytes: [{
        name: "The Archeion",
        x: 30.3,
        y: 11.4
    }, {
        name: "Sharlayan Hamlet",
        x: 21.6,
        y: 20
    }, {
        name: "Aporia",
        x: 6.6,
        y: 27
    }],
    aranks: [
        {
            x: 17,
            y: 9.6,
        },
        {
            x: 29.9,
            y: 8.2,
        },
        {
            x: 34.2,
            y: 13.5,
        },
        {
            x: 32.3,
            y: 25.9,
        },
        {
            x: 10.7,
            y: 19.3,
        },
        {
            x: 16.8,
            y: 16.8,
        },
        {
            x: 25.4,
            y: 25,
        },
        {
            x: 5.9,
            y: 33.6,
        },
        {
            x: 12.1,
            y: 35.3,
        },
        {
            x: 19.6,
            y: 38.6,
        }]
},
    {
        key: 'thav',
        name: "thav",
        fullname: "Thavnair",
        instances: 1,
        arank1: "Yilan",
        arank2: "Sugriva",
        aetherytes: [{
            name: "Palaka's Stand",
            x: 29.3,
            y: 16
        }, {
            name: "The Great Work",
            x: 10.6,
            y: 21.7
        }, {
            name: "Yedlihmad",
            x: 25.3,
            y: 33.5
        }],
        aranks: [
            {
                x: 29.5,
                y: 13.7,

            },
            {
                x: 32.6,
                y: 20.1,
            },
            {
                x: 26.8,
                y: 20.9,
            },
            {
                x: 27.7,
                y: 25.5,
            },
            {
                x: 20.5,
                y: 31.3,
            },
            {
                x: 18.4,
                y: 23.6,
            },
            {
                x: 17.9,
                y: 16.4,
            },
            {
                x: 14.3,
                y: 12.2,
            },
            {
                x: 18.5,
                y: 11.5,
            }]
    },
    {
        key: 'garl',
        name: "garl",
        instances: 1,
        fullname: "Garlemald",
        arank1: "Minerva",
        arank2: "Aegeiros",
        aetherytes: [{
            name: "Camp Broken Glass",
            x: 13,
            y: 30.5
        }, {
            name: "Tertium",
            x: 31.7,
            y: 17.4
        }],
        aranks: [
            {
                x: 29.1,
                y: 20.9,
            },
            {
                x: 33.1,
                y: 21.9,
            },
            {
                x: 32.5,
                y: 32.6,
            },
            {
                x: 27.6,
                y: 34.0,
            },
            {
                x: 23.4,
                y: 25.8,
            },
            {
                x: 15.9,
                y: 19.7,
            },
            {
                x: 11.8,
                y: 17.2,
            },
            {
                x: 12.1,
                y: 12.9,
            },
            {
                x: 9.9,
                y: 11.6,
            }]
    },
    {
        key: 'mare',
        name: "mare",
        instances: 1,
        fullname: "Mare Lamentorum",
        arank1: "Mousse Princess",
        arank2: "Lunatender Queen",
        aetherytes: [{
            name: "Sinus Lacrimarum",
            x: 9.8,
            y: 34.1
        }, {
            name: "Bestways Burrows",
            x: 21.2,
            y: 10.4
        }],
        aranks: [
            {
                x: 16.5,
                y: 28.8,
            },
            {
                x: 20.9,
                y: 34.7,
            },
            {
                x: 24.2,
                y: 33.5,
            },
            {
                x: 30,
                y: 30,
            },
            {
                x: 36.5,
                y: 27.1,
            },
            {
                x: 28.2,
                y: 26.8,
            },
            {
                x: 24.3,
                y: 23.5,
            },
            {
                x: 18.6,
                y: 21.7,
            },
            {
                x: 17.3,
                y: 24.8,
            },
            {
                x: 10.4,
                y: 24.1,
            }]
    },
    {
        key: 'elpis',
        name: "elpis",
        fullname: "Elpis",
        instances: 1,
        arank1: "Petalodus",
        arank2: "Gurangatch",
        aetherytes: [{
            name: "Anagnorisis",
            x: 24.3,
            y: 23.4
        }, {
            name: "The Twelve Wonders",
            x: 8.4,
            y: 32.1
        }, {
            name: "Poieten Oikos",
            x: 10.4,
            y: 16.7
        }],
        aranks: [
            {
                x: 6.9,
                y: 29,
            },
            {
                x: 12.9,
                y: 32.2,
            },
            {
                x: 18.0,
                y: 30.2,
            },
            {
                x: 18.6,
                y: 24.5,
            },
            {
                x: 29.7,
                y: 27.4,
            },
            {
                x: 32.7,
                y: 18.5,
            },
            {
                x: 34.2,
                y: 14.2,
            },
            {
                x: 33.9,
                y: 10.7,
            },
            {
                x: 21.3,
                y: 13.3,
            },
            {
                x: 21.7,
                y: 6.2,
            },
            {
                x: 12.9,
                y: 9.9,
            }]
    },
    {
        key: 'ultima',
        name: "ultima",
        fullname: "Ultima Thule",
        arank1: "Fan Ail",
        arank2: "Arch-Eta",
        aetherytes: [{
            name: "Reah Tahra",
            x: 10.2,
            y: 26.3
        }, {
            name: "Abode of the Ea",
            x: 22.3,
            y: 7.8
        }, {
            name: "Base Omicron",
            x: 31,
            y: 27.7
        }],
        aranks: [
            {
                x: 28,
                y: 12.3,
            },
            {
                x: 19.3,
                y: 9.7,
            },
            {
                x: 13.3,
                y: 10.4,
            },
            {
                x: 8.3,
                y: 20.3,
            },
            {
                x: 12,
                y: 22,
            },
            {
                x: 16.4,
                y: 26,
            },
            {
                x: 17.6,
                y: 30.3,
            },
            {
                x: 15,
                y: 36.1,
            },
            {
                x: 21.2,
                y: 34.2,
            }]

    }

]

exports.EWMap = EWMap
