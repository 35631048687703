import ScoutsToText from "./ScoutsToText";

export default function CopyScoutsToClipboard(expansions, scoutingMarks){
    let finalText = ''
    for (let idx in expansions) {
        let exp = expansions[idx]
        let text = ScoutsToText(exp, scoutingMarks)
        if (text.length === 0) continue
        finalText = finalText + text
    }
    if (finalText.length === 0) return
    navigator.clipboard.writeText(finalText);
}