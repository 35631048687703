const SHBMap = [{
    key: 'lakeland',
    name: "lakeland",
    fullname: "Lakeland",
    arank1: "Nariphon",
    arank2: "Nuckelavee",
    aetherytes: [{
        name: "Fort Jobb",
        x: 36.5,
        y: 20.4
    }, {
        name: "The Ostall Imperative",
        x: 6.4,
        y: 16.4
    }],
    aranks: [
        {
            x: 36.7,
            y: 12.3,
        },
        {
            x: 35.3,
            y: 16.0,
        },
        {
            x: 35.9,
            y: 26.8,
        },
        {
            x: 35.3,
            y: 32.1,
        },
        {
            x: 26.9,
            y: 37.5,
        },
        {
            x: 27.7,
            y: 30.5,
        },
        {
            x: 23.1,
            y: 29.8,
        },
        {
            x: 25.6,
            y: 23.8,
        },
        {
            x: 18.5,
            y: 23.0,
        },
        {
            x: 14.0,
            y: 24.7,
        },
        {
            x: 8,
            y: 22.9,
        },
        {
            x: 11.6,
            y: 17.2,
        },
        {
            x: 11.8,
            y: 12.8,
        },
        {
            x: 19.8,
            y: 9.6,
        },
        {
            x: 23.1,
            y: 12.3,
        },
        {
            x: 27.6,
            y: 15.5,
        },
        {
            x: 29.5,
            y: 19.1,
        },
        {
            x: 30.9,
            y: 22.2,
        }]
},
    {
        key: 'kholusia',
        name: "kholusia",
        fullname: "Kholusia",
        arank1: "Huracan",
        arank2: "L'il Murderer",
        aetherytes: [{
            name: "Stilltide",
            x: 34.7,
            y: 26.8
        }, {
            name: "Wright",
            x: 16.5,
            y: 28.8
        }, {
            name: "Tomra",
            x: 12.8,
            y: 8.6
        }],
        aranks: [
            {
                x: 16.8,
                y: 6.9,
            },
            {
                x: 19.7,
                y: 10.6,
            },
            {
                x: 25.0,
                y: 11.4,
            },
            {
                x: 22.0,
                y: 14.1,
            },
            {
                x: 22.8,
                y: 17.5,
            },
            {
                x: 26.6,
                y: 19.2,
            },
            {
                x: 31.2,
                y: 19.6,
            },
            {
                x: 21.3,
                y: 22.9,
            },
            {
                x: 15,
                y: 15.8,
            },
            {
                x: 11.6,
                y: 18.6,
            },
            {
                x: 9.4,
                y: 25.4,
            },
            {
                x: 15.1,
                y: 24.2,
            },
            {
                x: 21,
                y: 31.1,
            },
            {
                x: 24.5,
                y: 30.2,
            },
            {
                x: 29.9,
                y: 29.8,
            },
            {
                x: 26.8,
                y: 24.2,
            },
            {
                x: 34.5,
                y: 24.5,
            }]
    },
    {
        key: 'ahm',
        name: "ahm",
        fullname: "Amh Araeng",
        arank1: "Maliktender",
        arank2: "Sugaar",
        aetherytes: [{
            name: "Mord Souq",
            x: 26.1,
            y: 16.5
        }, {
            name: "The Inn at Journey's Head",
            x: 29.2,
            y: 27.3
        }, {
            name: "Twine",
            x: 11.1,
            y: 16.8
        }],
        instances: 1,
        aranks: [
            {
                x: 28.6,
                y: 12.5,
            },
            {
                x: 30.8,
                y: 13.8,
            },
            {
                x: 28.8,
                y: 20.4,
            },
            {
                x: 33.5,
                y: 21.6,
            },
            {
                x: 28.6,
                y: 26.1,
            },
            {
                x: 32.8,
                y: 33.9,
            },
            {
                x: 30.6,
                y: 35.1,
            },
            {
                x: 20,
                y: 36.4,
            },
            {
                x: 23.3,
                y: 29.8,
            },
            {
                x: 19.7,
                y: 29,
            },
            {
                x: 17,
                y: 31.7,
            },
            {
                x: 19.2,
                y: 24.7,
            },
            {
                x: 16.5,
                y: 24.1,
            },
            {
                x: 11.6,
                y: 19.4,
            },
            {
                x: 19.2,
                y: 16.1,
            },
            {
                x: 16.7,
                y: 10.1,
            },
            {
                x: 10.3,
                y: 11.7,
            }]
    },
    {
        key: 'mheg',
        name: "mheg",
        fullname: "Il Mheg",
        arank1: "O Poorest Pauldia",
        arank2: "The Mudman",
        aetherytes: [{
            name: "Lydha Lran",
            x: 14.4,
            y: 31.3
        }, {
            name: "Pla Enni",
            x: 19.8,
            y: 3.9
        }, {
            name: "Wolekdorf",
            x: 28.8,
            y: 7.3
        }],
        aranks: [
            {
                x: 19.3,
                y: 27.4,
            },
            {
                x: 23.1,
                y: 28.7,
            },
            {
                x: 24.2,
                y: 32.7,
            },
            {
                x: 25,
                y: 37.3,
            },
            {
                x: 19.9,
                y: 34.9,
            },
            {
                x: 13.9,
                y: 34.2,
            },
            {
                x: 9.7,
                y: 30.6,
            },
            {
                x: 8.1,
                y: 27.1,
            },
            {
                x: 7.5,
                y: 22.8,
            },
            {
                x: 10.5,
                y: 20.2,
            },
            {
                x: 11.1,
                y: 15.8,
            },
            {
                x: 20,
                y: 8.5,
            },
            {
                x: 25.5,
                y: 6.8,
            },
            {
                x: 29.2,
                y: 5.3,
            },
            {
                x: 34.3,
                y: 7.3,
            },
            {
                x: 31.5,
                y: 13.7,
            },
            {
                x: 27.2,
                y: 19,
            }]
    },
    {
        key: 'greatwood',
        name: "greatwood",
        fullname: "The Rak'tika Greatwood",
        arank1: "Grassman",
        arank2: "Supay",
        aetherytes: [{
            name: "Slitherbough",
            x: 19.4,
            y: 27
        }, {
            name: "Fanow",
            x: 28.7,
            y: 17.1
        }],
        instances: 1,
        aranks: [
            {
                x: 15,
                y: 30.2,
            },
            {
                x: 17.7,
                y: 34.9,
            },
            {
                x: 12.2,
                y: 35.8,
            },
            {
                x: 8.4,
                y: 34.5,
            },
            {
                x: 9.7,
                y: 24.1,
            },
            {
                x: 9.5,
                y: 18.5,
            },
            {
                x: 14.4,
                y: 22.2,
            },
            {
                x: 17,
                y: 24.3,
            },
            {
                x: 25,
                y: 30.4,
            },
            {
                x: 26.1,
                y: 24.1,
            },
            {
                x: 29.4,
                y: 25.8,
            },
            {
                x: 33.4,
                y: 22.8,
            },
            {
                x: 26.2,
                y: 15,
            },
            {
                x: 21.8,
                y: 13.5,
            },
            {
                x: 22.4,
                y: 10.7,
            }]
    },
    {
        key: 'tempest',
        name: "tempest",
        fullname: "The Tempest",
        arank1: "Rusalka",
        arank2: "Baal",
        aetherytes: [{
            name: "The Macarenses Angle",
            x: 18.3,
            y: 25.4
        }, {
            name: "The Ondu Cups",
            x: 32.6,
            y: 17.1
        }],
        aranks: [
            {
                x: 29.1,
                y: 23,
            },
            {
                x: 33.9,
                y: 21.7,
            },
            {
                x: 36.1,
                y: 19.8,
            },
            {
                x: 37.6,
                y: 16.4,
            },
            {
                x: 36.7,
                y: 11.5,
            },
            {
                x: 30.9,
                y: 11.1,
            },
            {
                x: 28.8,
                y: 8.4,
            },
            {
                x: 31.1,
                y: 4.0,
            },
            {
                x: 21.3,
                y: 7.3,
            },
            {
                x: 25.2,
                y: 12.6,
            },
            {
                x: 18.0,
                y: 13.4,
            },
            {
                x: 15.6,
                y: 10.6,
            },
            {
                x: 11.2,
                y: 4.8,
            },
            {
                x: 8.9,
                y: 8.8,
            },
            {
                x: 13.5,
                y: 17.3,
            },
            {
                x: 15.6,
                y: 19.9,
            },
            {
                x: 24.7,
                y: 25,
            }]
    }
]

exports.SHBMap = SHBMap
