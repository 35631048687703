import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Home from './Pages/Home'
import {Route, Routes} from "react-router";
import {BrowserRouter} from "react-router-dom";
import Scouting from "./scouting/Scouting";
import {Provider} from "react-redux";
import store from "./app/store";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <BrowserRouter>
            <div className="App">
                <Routes>
                    <Route exact path="/" element={<Home/>}/>
                    <Route path="/home" element={<Home/>}/>
                    <Route path="/scouting" element={<Scouting/>}/>
                    <Route path="/scouting/:code" element={<Scouting/>}/>
                </Routes>
            </div>
        </BrowserRouter>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();


