import ToggleSpot from "./ToggleSpot";
import {formatMapName} from "../../util/Utility";

const states = ["remove", "mark1", "mark2"]

function change(toggleData, currentTime, scoutingMarks, databaseOn, databaseCode, dispatch) {
    const current_mark = toggleData["status"]
    const expansion = toggleData["expansion"]
    const map = toggleData["map"]
    const instance = toggleData["instance"]
    const spot_idx = toggleData["index"]

    ToggleSpot(expansion, map, instance, spot_idx, current_mark, currentTime, scoutingMarks, databaseOn, databaseCode, dispatch)
}


function getOppositeStatus(type) {
    return type === "mark1" ? "mark2" : "mark1"
}

function nextStatus(status) {
    let index = states.indexOf(status) + 1
    if (index === 3) index = 0
    return states[index]

}

export default function ToggleMark(toggleData, scoutingMarks, databaseOn, databaseCode, dispatch) {
    let name = formatMapName(toggleData["expansion"], toggleData["map"], toggleData["instance"]);
    let instancedMapSpots = scoutingMarks === undefined? undefined : scoutingMarks[name]
    if (instancedMapSpots !== undefined) {
        instancedMapSpots = JSON.parse(JSON.stringify(instancedMapSpots))
    }

    toggleData["status"] = nextStatus(toggleData["status"])
    let existingMarks = []
    let oldIndex = undefined
    let oldStatus = undefined

    if (toggleData["status"] !== "remove" || instancedMapSpots === undefined) {

        for (let mark_idx in instancedMapSpots) {
            let mark = instancedMapSpots[mark_idx]
            if (mark["status"] === "remove") continue

            existingMarks.push({"status": mark["status"], "index": Number(mark["index"])})
        }

        if (existingMarks.length === 1 && existingMarks[0]["status"] === toggleData["status"]) {
            toggleData["status"] = getOppositeStatus(toggleData["status"])
        } else if (existingMarks.length === 2) {
            let existingMark = false
            for (let i in existingMarks) {
                let mark = existingMarks[i]
                if (mark["index"] === toggleData["index"]) {
                    existingMark = true
                    toggleData["status"] = "remove"
                    break
                }
            }

            if (!existingMark) {
                for (let i in existingMarks) {
                    let mark = existingMarks[i]
                    if (mark["status"] === toggleData["status"]) {
                        oldIndex = Number(mark["index"])
                        oldStatus = "remove"
                    }
                }
            }

        }
    }

    let currentTime = (new Date()).getTime();

    change(toggleData, currentTime, scoutingMarks, databaseOn, databaseCode, dispatch)
    const alternateToggleData =  JSON.parse(JSON.stringify(toggleData))

    if (oldIndex !== undefined && oldStatus !== undefined) {
        alternateToggleData["index"] = Number(oldIndex)
        alternateToggleData["status"] = oldStatus
        change(alternateToggleData, currentTime, scoutingMarks, databaseOn, databaseCode, dispatch)
    }

}