import {formatMapName} from "../../util/Utility";


export default function TooltipText(mapData, spotData, coords, scoutingMarks) {
    let name = formatMapName(spotData["expansion"], spotData["map"], spotData["instance"]);
    let instancedMapSpots = scoutingMarks === undefined? undefined : scoutingMarks[name]
    if (instancedMapSpots !== undefined) {
        instancedMapSpots = JSON.parse(JSON.stringify(instancedMapSpots))
    }

    let text = "(" + coords[0] + ", " + coords[1] + ")";
    let aRank = ""

    if (instancedMapSpots === undefined) return text

    let liveSpot = undefined

    for (let idx in instancedMapSpots) {
        let sp = instancedMapSpots[idx]
        if (sp["index"] === spotData["index"]) {
            liveSpot = sp
            break
        }
    }

    if (liveSpot !== undefined) {
        if (liveSpot["status"] === "mark1") aRank = mapData.arank1 + " "
        else if (liveSpot["status"] === "mark2") aRank = mapData.arank2 + " "
    }

    return aRank + text;
}