import React, {useCallback, useState} from 'react';
import './Home.css';
import SirenNavigation from "./SirenNavigation";
import {Box, Grid, Typography} from "@mui/material";
import CharacterCard from "./CharacterCard";


function Home() {

    let [characterCards, setCharacterCards] = useState([])


    const updateCards = useCallback(
        (card) => {
            let cCards = [...characterCards]
            if (characterCards.includes(card)) {
                cCards = cCards.filter(function (item) {
                    return item !== card
                })
            } else {
                cCards.push(card)
            }

            setCharacterCards(cCards)
        }
    )

    return (
        <div className="home">
            <SirenNavigation/>
            <div id="text-home"
                 style={{
                     display: 'flex',
                     alignItems: 'center',
                     justifyContent: 'center',
                 }}
            >
                <Box
                    sx={{
                        padding: '20px',
                        flexWrap: 'wrap',
                        '& > :not(style)': {
                            m: 1,
                            minWidth: "400px",
                            width: "70vw",
                            height: "auto",
                        },
                    }}
                >
                    <div className="grid-background">
                        <Grid container spacing={2}>

                            <Grid item xs={4}><img src={require("../content/hunt-image.png")} className="photo"
                                                   alt="Siren Hunters"/></Grid>
                            <Grid item xs={8}>
                                <div class="top-text">

                                    <Typography variant="h4">Hey Friend!</Typography><Typography variant="body1">
                                    If you are from Siren or just want to use our scouting tool, welcome! Siren is
                                    an
                                    small,
                                    inclusive world on Aether! We encourage anyone who wants to participate in our
                                    hunt
                                    community
                                    to do so! If you don't know who to talk to to get involved, you can always make
                                    a
                                    support
                                    ticket in our
                                    discord server!<br/><br/>If you have suggestions to improve this site, feel free
                                    to
                                    make a ticket as well!</Typography>

                                </div>
                            </Grid>

                        </Grid>
                    </div>
                    <div class="admin-title-container">
                        <h1 class="admin-title">SHC Leads</h1>
                    </div>
                    <div className="grid-cards">
                        <Grid container spacing={3}>
                            <CharacterCard updateCards={updateCards} characterCards={characterCards}/>
                        </Grid>
                    </div>
                </Box>
            </div>
        </div>
    );

}

export default Home;