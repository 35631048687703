const HuntStyle = {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "min(10px, 1vw)",
    minWidth: "min(400px,80vw)",
    width: "min(400px,80vw)",
    alignItems: "center",
    justifyContent: "center"
}

const MapRowStyle = (rows) => {
    return {
        display: "grid",
        gridTemplateColumns: "repeat(" + rows + ", 1fr)",
        gridGap: 5,
        justifyContent: "center",
        justifyItems: "center"
    }
}

exports.HuntStyle = HuntStyle
exports.MapRowStyle = MapRowStyle