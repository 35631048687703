const STBMap = [{
    key: 'fringes',
    name: "fringes",
    fullname: "The Fringes",
    arank1: "Erle",
    arank2: "Orcus",
    aetherytes: [{
        name: "Castrum Oriens",
        x: 8.4,
        y: 10.9
    }, {
        name: "The Peering Stones",
        x: 29.8,
        y: 26
    }],
    aranks: [
        {
            x: 18.3,
            y: 8.4
        },
        {
            x: 17.2,
            y: 12.7
        },
        {
            x: 14.4,
            y: 12.4
        },
        {
            x: 10.4,
            y: 14.5
        },
        {
            x: 12.3,
            y: 18.0
        },
        {
            x: 8.7,
            y: 25.2
        },
        {
            x: 8.3,
            y: 30.5
        },
        {
            x: 34.6,
            y: 17.5
        },
        {
            x: 17.5,
            y: 21.7
        },
        {
            x: 27.7,
            y: 23.5
        },
        {
            x: 16.7,
            y: 24.3
        },
        {
            x: 15.4,
            y: 26.3
        },
        {
            x: 25.2,
            y: 28.4
        },
        {
            x: 25.8,
            y: 31.8
        },
        {
            x: 33.3,
            y: 33.4
        }]
},
    {
        key: 'peaks',
        name: "peaks",
        fullname: "The Peaks",
        arank1: "Aqrabuamelu",
        arank2: "Vochstein",
        aetherytes: [{
            name: "Ala Gannha",
            x: 23.5,
            y: 6.1
        }, {
            name: "Ala Ghiri",
            x: 15.7,
            y: 36.2
        }],
        aranks: [
            {
                x: 26.9,
                y: 7.9
            },
            {
                x: 11.2,
                y: 8.2
            },
            {
                x: 32.0,
                y: 8.3
            },
            {
                x: 26.2,
                y: 12.0
            },
            {
                x: 8.7,
                y: 12.3
            },
            {
                x: 36.3,
                y: 12.5
            },
            {
                x: 5.6,
                y: 14.8
            },
            {
                x: 23.3,
                y: 14.9
            },
            {
                x: 25.3,
                y: 20.1
            },
            {
                x: 16.7,
                y: 23.1
            },
            {
                x: 23.7,
                y: 24.2
            },
            {
                x: 7.8,
                y: 25.8
            },
            {
                x: 18.1,
                y: 26.7
            },
            {
                x: 12.6,
                y: 28.3
            },
            {
                x: 11.7,
                y: 32.9
            },
            {
                x: 5.8,
                y: 34.8
            },
            {
                x: 23.6,
                y: 36.6
            }]
    },
    {
        key: 'lochs',
        name: "lochs",
        fullname: "The Lochs",
        arank1: "Luminare",
        arank2: "Mahisha",
        aetherytes: [{
            name: "Porta Praetoria",
            x: 8,
            y: 20.8
        }, {
            name: "The Ala Mhigan Quarter",
            x: 33.5,
            y: 34.1
        }],
        aranks: [
            {
                x: 28.9,
                y: 7.9
            },
            {
                x: 6.4,
                y: 8.2
            },
            {
                x: 7.7,
                y: 14.1
            },
            {
                x: 13.9,
                y: 20.6
            },
            {
                x: 18.8,
                y: 25.7
            },
            {
                x: 7.4,
                y: 26.3
            },
            {
                x: 6.9,
                y: 31.1
            },
            {
                x: 24.5,
                y: 32.8
            },
            {
                x: 26.4,
                y: 34.5
            },
            {
                x: 30.1,
                y: 36.4
            },
            {
                x: 35.0,
                y: 11.2
            },
            {
                x: 4.6,
                y: 29.3
            },
            {
                x: 3.1,
                y: 35.0
            },
            {
                x: 19.0,
                y: 31.8
            }]
    },
    {
        key: 'ruby',
        name: "ruby",
        fullname: "The Ruby Sea",
        arank1: "Funa Yurei",
        arank2: "Oni Yumemi",
        aetherytes: [{
            name: "Onokoro",
            x: 23,
            y: 9.5
        }, {
            name: "Tamamizu",
            x: 28.4,
            y: 15.8
        }],
        aranks: [
            {
                x: 26.0,
                y: 7.0
            },
            {
                x: 18.9,
                y: 8.6
            },
            {
                x: 14.5,
                y: 13.9
            },
            {
                x: 5.0,
                y: 22.9
            },
            {
                x: 32.0,
                y: 24.3
            },
            {
                x: 6.9,
                y: 29.8
            },
            {
                x: 27.2,
                y: 31.6
            },
            {
                x: 23.9,
                y: 33.2
            },
            {
                x: 31.0,
                y: 38.7
            }]
    },
    {
        key: 'yanxia',
        name: "yanxia",
        fullname: "Yanxia",
        arank1: "Angada",
        arank2: "Gajasura",
        aetherytes: [{
            name: "The House of the Fierce",
            x: 26,
            y: 13
        }, {
            name: "Namai",
            x: 29.7,
            y: 19.3
        }],
        aranks: [
            {
                x: 21.6,
                y: 9.4
            },
            {
                x: 27.1,
                y: 10.4
            },
            {
                x: 28.9,
                y: 11.4
            },
            {
                x: 23.6,
                y: 11.4
            },
            {
                x: 16.9,
                y: 13.8
            },
            {
                x: 18.3,
                y: 16.1
            },
            {
                x: 12.3,
                y: 30.5
            },
            {
                x: 17.6,
                y: 34.3
            }]
    },
    {
        key: 'azim',
        name: "azim",
        fullname: "The Azim Steppe",
        arank1: "Girimekhala",
        arank2: "Sum",
        aetherytes: [{
            name: "Dhoro Iloh",
            x: 6,
            y: 23.4
        }, {
            name: "The Dawn Throne",
            x: 22.7,
            y: 21.7
        }, {
            name: "Reunion",
            x: 32.3,
            y: 27.9
        }],
        aranks: [
            {
                x: 27.4,
                y: 9.0
            },
            {
                x: 19.3,
                y: 10.2
            },
            {
                x: 13.5,
                y: 10.4
            },
            {
                x: 34.6,
                y: 15.0
            },
            {
                x: 22.3,
                y: 16.0
            },
            {
                x: 12.5,
                y: 17.3
            },
            {
                x: 27.9,
                y: 18.8
            },
            {
                x: 17.4,
                y: 18.9
            },
            {
                x: 9.6,
                y: 22.3
            },
            {
                x: 15.0,
                y: 25.5
            },
            {
                x: 27.9,
                y: 25.7
            },
            {
                x: 9.7,
                y: 25.9
            },
            {
                x: 11.3,
                y: 28.3
            },
            {
                x: 24.2,
                y: 30.3
            },
            {
                x: 15.6,
                y: 30.3
            },
            {
                x: 21.8,
                y: 34.2
            },
            {
                x: 26.7,
                y: 34.3
            },
            {
                x: 17.4,
                y: 34.4
            }]
    }
]

exports.STBMap = STBMap