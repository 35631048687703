import {Box, ButtonGroup, Typography} from "@mui/material";
import React from "react";
import ExpansionMap from "../data/ExpansionData";
import Grid from "@mui/material/Grid";
import ExpansionButton from "./ExpansionButton";


function ExpansionMenu(props) {

    let expansionButtons = []
    for (let expansion in ExpansionMap) {
        expansionButtons.push(<ExpansionButton key={expansion + "button"} expansion={expansion} updateExpansion={props.updateExpansion} />)
    }
    return (
        <Grid m={1}>
            <Box>
                <Typography id="expansions-typ" variant="button" align="center" display="block" gutterBottom>
                    Expansions
                </Typography>
                <ButtonGroup label="Expansions" color="secondary" size="small"
                             aria-label="primary button group">
                    {expansionButtons}
                </ButtonGroup>
            </Box>
        </Grid>

    )
}

export default ExpansionMenu