import {createSlice} from '@reduxjs/toolkit'
import ExpansionMap from "../data/ExpansionData";


function getMaxCounts(){
    let max_count = []
    for (let exp in ExpansionMap) {
        let count = 0
        let expansionMeta = ExpansionMap[exp]
        let map = expansionMeta.map
        for (let mapIdx in map) {
            let mapData = map[mapIdx]
            let instances = mapData.instances === undefined ? 1 : mapData.instances;

            count += instances * expansionMeta.multiplier
        }
        max_count.push(count)
    }
    return max_count
}

function getDefaultFilled() {
    let max_count = []
    for (let i = 0; i < Object.keys(ExpansionMap).length; i++) {
        max_count.push(0)
    }
    return max_count
}

export const PageMetaSlice = createSlice({
    name: 'rows',
    initialState: {
        max_count: getMaxCounts(),
        scouted_count: getDefaultFilled()
    },
    reducers: {
        setScouted: (state, action) => {
            state.scouted_count[action.payload.expansion_index] = action.payload.value
        },
    },
})

// Action creators are generated for each case reducer function
export const {  setScouted} = PageMetaSlice.actions

export default PageMetaSlice.reducer