import React from 'react';
import MarkFactory from "./coord-spots/MarkFactory";
import ExpansionMap from "../data/ExpansionData";
import {Box} from "@mui/material";
import {useSelector} from "react-redux";
import ARankTitle from "./ARankTitle";
import MapTitle from "./MapTitle";

//The Code that checks for instances.
function BuildMaps(props) {
    const rows = useSelector((state) => state.rows.value)

    function getStyle() {
        return {
            maxWidth: "1024px",
            maxHeight: "1024px",
            margin: "auto",
            width: (96 / rows) + "vw",
            height: (96 / rows) + "vw",
        }
    }

    let mapData = props.mapData
    let spotDataOG = props.spotData



    let spotDataMap = JSON.parse(JSON.stringify(spotDataOG))
    spotDataMap["map"] = mapData.name
    let total = [];
    let instances = mapData.instances === undefined ? 1 : mapData.instances;

    for (let i = 0; i < instances; i++) {
        let spotData = JSON.parse(JSON.stringify(spotDataMap))
        spotData["instance"] = i + 1

        let aRanksNames = [<ARankTitle key={mapData.name + "-arank1"} name={mapData.arank1} instance={spotData["instance"]} instanceCount={instances} rank={1} />]
        if (spotData["expansion"] !== "arr") {
            aRanksNames.push(<ARankTitle key={mapData.name + "-arank2"} name={mapData.arank2} instance={spotData["instance"]} instanceCount={instances} rank={2} />)
        }

        let imgType = ExpansionMap[spotData["expansion"]].imgType

        let mapLocation = require("../scouting-images/" + spotData["expansion"] + "/" + mapData.name + imgType);

        total.push(
            <div id={mapData.name + i} className="htt zonecnt" key={mapData.name + i}>
                <MarkFactory mapData={mapData} spotData={spotData} />

                <MapTitle key={mapData.name + "-title"} name={mapData.othername !== undefined ? mapData.othername : mapData.fullname} instance={spotData["instance"]} instanceCount={instances} />
                <Box>
                    {aRanksNames}
                </Box>

                <img src={mapLocation} className="zone w1024" style={getStyle()}
                     alt={mapData.img} key={mapData.name + "-image-" + i}/>
            </div>
        )
    }
    return total
}

export default BuildMaps




