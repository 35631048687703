import {createSlice} from '@reduxjs/toolkit'

export const DatabaseCodeSlice = createSlice({
    name: 'databaseCode',
    initialState: {
        value: undefined
    },
    reducers: {
        setDatabaseCode: (state, action) => {
            state.value = action.payload
        }
    },
})

export const { setDatabaseCode } = DatabaseCodeSlice.actions

export default DatabaseCodeSlice.reducer