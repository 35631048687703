import Tooltip from "@mui/material/Tooltip";
import React from "react";
import {useSelector} from "react-redux";
import {IconButton} from "@mui/material";
import ExpansionMap from "../../data/ExpansionData";

function Aetheryte(props) {

    const rows = useSelector((state) => state.rows.value)

    function xyToStyle(x, y) {
        let topStyle = createMinFieldFromCoordinate(y)
        let leftStyle = createMinFieldFromCoordinate(x)

        return {
            top: topStyle,
            left: leftStyle,
            height: "min(" + 3 / rows + "vw,30px)",
            width: "min(" + 3 / rows + "vw,30px)",
            position: "absolute",
            zIndex: 2,
            cursor: "pointer",
        }
    }

    function createMinFieldFromCoordinate(coord) {
        let mapPercentage = coord / ExpansionMap[props.expansion].width;

        let size = ((window.innerWidth - (window.innerWidth * .04)))

        let width = 1024
        let item = Math.min(size * .02, 20)

        let pixels = (mapPercentage * width) - item
        let truePercent = ((pixels / width) * 100)
        return "min(" + pixels + "px," + truePercent + "%)"
    }

    let aetheryte = require("../../../content/aetheryte.png");
    let scale = 30 - ((rows - 1) * 3)

    return (
        <Tooltip key={"tooltip" + props.spotId} title={props.name}
                 placement="top" arrow>
            <IconButton
                style={xyToStyle(props.x, props.y)}
                className="aetheryte"

                key={props.spotId}
                id={props.spotId}>

                <img src={aetheryte} width={scale + "px"} height={scale + "px"} alt="Aetheryte"/>
            </IconButton>
        </Tooltip>
    )
}

export default Aetheryte