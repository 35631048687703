import {createSlice} from '@reduxjs/toolkit'

export const RowsSlice = createSlice({
    name: 'rows',
    initialState: {
        value: 1,
    },
    reducers: {
        incrementRows: (state) => {
            state.value += 1
        },
        decrementRows: (state) => {
            state.value -= 1
        },
        setRows: (state, action) => {
            state.value = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {  incrementRows, decrementRows, setRows} = RowsSlice.actions

export default RowsSlice.reducer