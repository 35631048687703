import {createSlice} from '@reduxjs/toolkit'

export const LoadedSlice = createSlice({
    name: 'loaded',
    initialState: {
        value: false,
    },
    reducers: {
        toggleLoaded: (state) => {
            state.value = !state.value
        },
        setLoaded: (state, action) => {
            state.value = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { toggleLoaded, setLoaded } = LoadedSlice.actions

export default LoadedSlice.reducer