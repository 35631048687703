import Tooltip from "@mui/material/Tooltip";
import TooltipText from "./TooltipText";
import ToggleMark from "./ToggleMark";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ExpansionMap from "../../data/ExpansionData";

function CoordinateSpot(props) {
    const scoutingMarks = useSelector((state) => state.scoutingMarks.value)
    const rows = useSelector((state) => state.rows.value)
    const databaseOn = useSelector((state) => state.databaseOn.value)
    const databaseCode = useSelector((state) => state.databaseCode.value)

    const [hover, setHover] = useState(false)

    const dispatch = useDispatch()

    const statuses = ["remove", "mark1", "mark2"]
    const colors = ["#777777", "#6666ff", "#66d7bb"]
    const hoverColors = ["#a1a1a1", "#7777fc", "#84e8cf"]
    const borders = ["#707070 solid 2px", "#707070 solid 2px", "#707070 solid 2px"]
    const hoverBorders = ["#ffffff solid 2px", "#ffffff solid 2px", "#ffffff solid 2px"]
    const opacities = [0.7, 1, 1]

    function xyToStyle(x, y, status) {
        let topStyle = createMinFieldFromCoordinate(y)
        let leftStyle = createMinFieldFromCoordinate(x)

        let index = statuses.indexOf(status)
        let color = hover? hoverColors[index] : colors[index]
        let border = hover? hoverBorders[index] : borders[index]

        return {
            top: topStyle,
            left: leftStyle,
            height: "min(" + 3 / rows + "vw,30px)",
            width: "min(" + 3 / rows + "vw,30px)",
            position: "absolute",
            zIndex: 2,
            color: "#ffffff",
            border: border,
            backgroundColor: color,
            borderRadius: "50%",
            opacity: opacities[index],
            lineHeight: "1em",
            textAlign: "center",
            cursor: "pointer",
        }
    }

    function createMinFieldFromCoordinate(coord) {
        let mapPercentage = coord / ExpansionMap[props.spotData["expansion"]].width;

        let size = ((window.innerWidth - (window.innerWidth * .04)))

        let width = 1024
        let item = Math.min(size * .02, 20)

        let pixels = (mapPercentage * width) - item
        let truePercent = ((pixels / width) * 100)
        return "min(" + pixels + "px," + truePercent + "%)"
    }

    let spotData = props.spotData

    let status = spotData["status"]
    let text = status === "remove"? "" : status.slice(-1)

    return (
        <Tooltip key={"tooltip" + props.spotId} title={TooltipText(props.mapData, spotData, [props.x, props.y], scoutingMarks)}
                 placement="top" arrow>
            <button
                style={xyToStyle(props.x, props.y, status)}
                className="mark"
                onMouseEnter={() => {setHover(true)}}
                onMouseLeave={() => {setHover(false)}}
                onClick={() =>
                    ToggleMark(spotData, scoutingMarks, databaseOn, databaseCode, dispatch)}
                key={props.spotId}
                id={props.spotId}>
                {text}
            </button>
        </Tooltip>
    )
}

export default CoordinateSpot