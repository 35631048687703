import {createSlice} from '@reduxjs/toolkit'

export const ExpansionsSlice = createSlice({
    name: 'expansions',
    initialState: {
        value: ["dt"],
    },
    reducers: {
        addExpansion: (state, action) => {
            state.value.push(action.payload)
        },
        removeExpansion: (state, action) => {
            if (state.value.length === 1) return
            const index = state.value.indexOf(action.payload);
            if (index > -1) {
                state.value.splice(index, 1);
            }
        },
        setExpansions: (state, action) => {
            state.value = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { addExpansion, removeExpansion, setExpansions } = ExpansionsSlice.actions

export default ExpansionsSlice.reducer