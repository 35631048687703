import {Box, ButtonGroup, IconButton} from "@mui/material";
import React from 'react';

import {useDispatch, useSelector} from "react-redux";
import {setRows} from "../state-objects/RowsSlice";
import Grid from "@mui/material/Grid";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tooltip from "@mui/material/Tooltip";

export default function SizingColumns(props) {

    const rows = useSelector((state) => state.rows.value)
    const dispatch = useDispatch()

    const onDecrease = () => {
        let rows_value = rows - 1
        if (rows_value < 1) {
            rows_value = 1
        }
        dispatch(setRows(rows_value))
    }

    const onIncrease = () => {
        let rows_value = rows + 1
        if (rows_value > 9) {
            rows_value = 9
        }
        dispatch(setRows(rows_value))
    }


    return (
        <Grid xs={1} m={1}>
            <Box>
                <ButtonGroup label="sizing" color="secondary" size="small" aria-label="sizing button group" orientation="vertical">
                    <Tooltip title="More Rows" placement="top" arrow>
                    <IconButton  color="secondary" size="small" onClick={() => {onIncrease()}} ><KeyboardArrowUpIcon/></IconButton>
                    </Tooltip>
                    <Tooltip title="Less Rows" placement="top" arrow>
                    <IconButton color="secondary" size="small" onClick={() => {onDecrease()}} ><KeyboardArrowDownIcon/></IconButton>
                    </Tooltip>
                </ButtonGroup>
            </Box>
        </Grid>

    )
}