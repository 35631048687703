import {Box, ButtonGroup, Grid} from "@mui/material";
import {HuntStyle} from "./ScoutsStyles";
import ExpansionMenu from "./map-building/ExpansionMenu";
import SizingColumns from "./map-building/SizingColumns";
import React, {useCallback} from "react";
import {addExpansion, removeExpansion} from "./state-objects/ExpansionsSlice";
import {useDispatch, useSelector} from "react-redux";
import InformationPanel from "./util/InformationPanel";
import ToolsPopUp from "./util/ToolsPopUp";

function ScoutingTopMenu() {

    const expansions = useSelector((state) => state.expansions.value)

    const dispatch = useDispatch()

    const updateExpansion = useCallback(
        (expansion) => {
            if (expansions.includes(expansion)) {
                dispatch(removeExpansion(expansion))
            } else {
                dispatch(addExpansion(expansion))
            }
        }
    )

    return (
        <div className="hunt-options-poof">
            <Grid spacing={12} container bgcolor="white" borderRadius={3} sx={HuntStyle}>
                <Box display="flex" justifyContent="flex-start">
                    <SizingColumns/>
                </Box>

                <ExpansionMenu updateExpansion={updateExpansion}/>

                <ButtonGroup orientation="vertical">
                    <InformationPanel/>
                    <ToolsPopUp/>
                </ButtonGroup>

            </Grid>
        </div>
    );
}

export default ScoutingTopMenu;