import ExpansionMap from "../data/ExpansionData";
import {formatMapName} from "../util/Utility";

const instances = ["", "", "", "", "", ""]
const instanceNames = ["ONE", "TWO", "THREE", "FOUR", "FIVE", "SIX"]
const mapSymbol = ""

function ScoutsToText(exp, scoutingMarks) {

    let map = ExpansionMap[exp].map
    let result = ''

    for (let mPos in map) {
        let item = map[mPos]

        let instanceCount = 1;
        if (item.instances !== undefined) instanceCount = item.instances;

        for (let instance = 0; instance < instanceCount; instance++) {
            let firstMark = -1;
            let secondMark = -1;

            let scoutData = scoutingMarks[formatMapName(exp, item.name, (instance + 1))]

            for (let idx in scoutData) {
                let scoutObj = scoutData[idx]
                if (scoutObj["status"] === "mark1") firstMark = scoutObj["index"]
                else secondMark = scoutObj["index"]
            }

            let instanceIcon = instanceCount > 1 ? instances[instance] : "";
            let instancePlainText = instanceCount > 1 ? " (Instance " + instanceNames[instance] + ")" : "";

            if (firstMark !== -1) {
                result = result + createString(item, item.arank1, instanceIcon, instancePlainText, firstMark)
            }

            if (secondMark !== -1) {
                result = result + createString(item, item.arank2, instanceIcon, instancePlainText, secondMark)
            }
        }
    }

    return result;
}

function createString(item, aRank, instanceString, instanceAdd, loc) {
    return "(Maybe: " + aRank + ") " + mapSymbol + item.fullname + instanceString +
        " ( " + item.aranks[loc].x + "  , " + item.aranks[loc].y + " ) " + instanceAdd + "\n"
}

export default ScoutsToText