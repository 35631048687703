import {formatMapName} from "../../util/Utility";
import React from "react";
import {useSelector} from "react-redux";
import CoordinateSpot from "./CoordinateSpot";
import Aetheryte from "./Aetheryte";


function MarkFactory(props) {

    const scoutingMarks = useSelector((state) => state.scoutingMarks.value)

    let mapData = props.mapData
    let spotDataOG = props.spotData

    let instancedMapName = formatMapName(spotDataOG["expansion"], spotDataOG["map"], spotDataOG["instance"])
    let clickedSpotsOnMap = scoutingMarks[instancedMapName]
    if (clickedSpotsOnMap === undefined) clickedSpotsOnMap = []

    const row = [];
    for (let i = 0; i < mapData.aranks.length; i++) {
        let spotData = JSON.parse(JSON.stringify(spotDataOG))
        spotData["index"] = i
        spotData["status"] = "remove"
        for (let idx in clickedSpotsOnMap) {
            let marked = clickedSpotsOnMap[idx]
            if (marked["index"] !== spotData["index"]) continue
            spotData["status"] = marked["status"]
        }

        let spotId = instancedMapName + "-" + i;

        let coord = mapData.aranks[i];
        if (coord === undefined) continue



        let prettyX = coord.x.toFixed(1);
        let prettyY = coord.y.toFixed(1);

        row.push(
            <CoordinateSpot key={"cp-" + spotId} x={prettyX} y={prettyY} mapData={mapData}
                            spotData={spotData} spotId={spotId}/>
        );
    }

    if (mapData.aetherytes !== undefined) {
        for (let i = 0; i < mapData.aetherytes.length; i++) {

            let spotId = instancedMapName + "-aetheryte-" + i;

            let coord = mapData.aetherytes[i];
            if (coord === undefined) continue

            let prettyX = coord.x.toFixed(1);
            let prettyY = coord.y.toFixed(1);

            row.push(<Aetheryte key={"cp-" + spotId} x={prettyX} y={prettyY} expansion={spotDataOG["expansion"]}
                                name={mapData.aetherytes[i].name}
                                spotId={spotId}/>)
        }
    }
    return row;
}


export default MarkFactory