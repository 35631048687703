import {configureStore} from "@reduxjs/toolkit"
import expansionsReducer from '../scouting/state-objects/ExpansionsSlice'
import loadedReducer from '../scouting/state-objects/LoadedSlice'
import rowsReducer from '../scouting/state-objects/RowsSlice'
import sortTypeReducer from '../scouting/state-objects/SortTypeSlice'
import scoutingMarksReducer from '../scouting/state-objects/ScoutingMarksSlice'
import databaseOnReducer from '../scouting/state-objects/DatabaseOnSlice'
import databaseCodeReducer from '../scouting/state-objects/DatabaseCodeSlice'
import pageMetaReducer from '../scouting/state-objects/PageMetaSlice'


export default configureStore({
    reducer: {
        expansions: expansionsReducer,
        loaded: loadedReducer,
        rows: rowsReducer,
        sortType: sortTypeReducer,
        scoutingMarks: scoutingMarksReducer,
        databaseOn: databaseOnReducer,
        databaseCode: databaseCodeReducer,
        pageMeta: pageMetaReducer
    },
})