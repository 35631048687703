import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Fragment, useState} from "react";
import {Box, Button} from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';

function InformationPanel() {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Button size="small" color="secondary" startIcon={<InfoOutlinedIcon/>} variant="outlined" onClick={handleClickOpen}>
                Info
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box>
                    <DialogTitle id="alert-dialog-title">
                        {"About Siren Hunts Scouting Tool"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            This tool was created by FFXIV player Airenlei Saerel (Siren/Cactuar).
                            <br/><br/>
                            The tool was originally based on code from long time FFXIV hunter Maya Konoe (Midgardsormr).
                            <br/><br/>
                            Found a Bug? / Want a feature?
                            <br/>
                            DM me on discord @pandette or make a ticket on Siren Hunts Community discord.
                        </DialogContentText>
                    </DialogContent>
                </Box>
            </Dialog>
        </Fragment>
    )
}

export default InformationPanel