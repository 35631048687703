import {Button} from "@mui/material";
import React from "react";
import HashCreator from "./HashCreator";
import {FiShare} from "react-icons/all";
import ExpansionMap from "../data/ExpansionData";
import {useSelector} from "react-redux";
import {formatMapName} from "../util/Utility";
import Tooltip from "@mui/material/Tooltip";

function sharemarks(code, databaseCode) {
    let href = window.location.href;


    if (href.endsWith("/")) {
        href = href.substring(0, href.length - 1)
    }

    let hrefArray = href.split("/")
    let last = hrefArray[hrefArray.length - 1];

    if (last.includes(">") || last.includes("%3E")) {
        href = href.replace("/" + last, "")
    }

    let url = href + "/" + code
    if (databaseCode !== undefined && url.includes(databaseCode)) url = url.replace(databaseCode + "/", "")
    return url
}

export function createCode(prefix, expMap, scoutingMarks) {
    let code = prefix
    let lower = prefix.toLowerCase().substring(0, prefix.length - 1)

    expMap.forEach(item => {
            let inst = 1;
            if (item.instances !== undefined) {
                inst = item.instances;
            }

            for (let i = 0; i < inst; i++) {
                code = code + HashCreator(scoutingMarks[formatMapName(lower, item.name, (i + 1))], prefix === "ARR>")
            }
        }
    )

    return code;
}

//This is the HTML for the share Mark info
export default function ShareLinkNav() {
    const expansions = useSelector((state) => state.expansions.value)
    const databaseCode = useSelector((state) => state.databaseCode.value)
    const scoutingMarks = useSelector((state) => state.scoutingMarks.value)

    return (
        <Tooltip title="Get Permanent Scouts Link"  placement="top" arrow>
                <Button size="small" startIcon={<FiShare/>} className="rgbtn" onClick={() => {
                    if (expansions.length === 0) {
                        //TODO: error alert.
                        return;
                    }

                    let code = ''
                    for (let idx in expansions) {
                        let exp = expansions[idx]
                        code = code + createCode(exp.toUpperCase() + ">", ExpansionMap[exp].map, scoutingMarks) + "&"
                    }

                    code = code.substring(0, code.length - 1);


                    navigator.clipboard.writeText(sharemarks(code, databaseCode))
                }}>Share</Button>

        </Tooltip>

    )
}