import ToggleSpot from "../map-building/coord-spots/ToggleSpot";
import {setExpansions} from "../state-objects/ExpansionsSlice";
import {extractMapName} from "../util/Utility";
import ScoutingUpdate from "./ScoutingUpdate";
import CallChangeAPI from "./CallChangeAPI";

function fromLocalToDatabase(databaseOn, scoutingMarks, databaseCode) {
    let list = "["
    if (databaseOn && scoutingMarks !== undefined) {
        for (let expMapInstanceName in scoutingMarks) {
            let dataContainer = scoutingMarks[expMapInstanceName]
            let expName, mapName, instance;
            [expName, mapName, instance] = extractMapName(expMapInstanceName)
            for (let listIdx in dataContainer) {
                let spot = dataContainer[listIdx]
                list = list + ScoutingUpdate(expName, mapName, instance, spot["index"], spot["status"], spot["timeStamp"]) + ","
            }
        }
    }

    if (list.length > 1) {
        CallChangeAPI(list.substring(0, list.length - 1) + "]", databaseOn, databaseCode).then()
    }
}

export default function ToggleExpansionFromDataBase(expMap, scoutingMarks, databaseOn, databaseCode, dispatch) {
    fromLocalToDatabase(databaseOn, scoutingMarks, databaseCode);

    if (expMap.expansions === undefined) return
    let scoutedExpansions = []
    let changeExpansion = false
    for (let ex in expMap.expansions) {
        let expan = expMap.expansions[ex]
        for (let mn in expan["maps"]) {
            let mapName = expan["maps"][mn]
            for (let inst in mapName["spots"]) {
                let instance = mapName["spots"][inst]
                for (let i in instance) {
                    let spot = instance[i]
                    let spotName = ex + "-" + mn + "-" + inst;
                    let cur = scoutingMarks[spotName];
                    let mstatus = spot["status"]
                    let stat = "remove"
                    if (mstatus !== 0) stat = "mark" + mstatus
                    let exists = false

                    if (cur === undefined) {
                        changeExpansion = true
                    }
                    for (let idx in cur) {
                        let cur_spot = cur[idx];
                        if (cur_spot["index"] === spot["index"]) {
                            exists = true
                            if (cur_spot["status"] !== stat) {
                                ToggleSpot(ex, mn, inst, spot["index"], stat, spot["timeStamp"], scoutingMarks, databaseOn, databaseCode, dispatch)
                            }
                        }

                    }

                    if (!scoutedExpansions.includes(ex)) scoutedExpansions.push(ex)

                    if (exists) continue
                    ToggleSpot(ex, mn, inst, spot["index"], stat, spot["timeStamp"], scoutingMarks, databaseOn, databaseCode, dispatch)
                }
            }
        }
    }

    if (changeExpansion) {
        dispatch(setExpansions(scoutedExpansions))
    }


}