import {createSlice} from '@reduxjs/toolkit'

export const DatabaseOnSlice = createSlice({
    name: 'databaseOn',
    initialState: {
        value: false,
    },
    reducers: {
        toggleDatabaseOn: (state) => {
            state.value = !state.value
        },
        setDatabaseOn: (state, action) => {
            state.value = action.payload
        }
    },
})

export const { toggleDatabaseOn, setDatabaseOn } = DatabaseOnSlice.actions

export default DatabaseOnSlice.reducer