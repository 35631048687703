const HWMap = [{
    key: 'cwh',
    name: "cwh",
    fullname: "Coerthas Western Highlands",
    arank1: "Lyuba",
    arank2: "Mirka",
    aetherytes: [{
        name: "Falcon's Nest",
        x: 32.1,
        y: 36.3
    }],
    aranks: [{
        x: 25,
        y: 32.4,
    }, {
        x: 23.5,
        y: 28.1,
    }, {
        x: 18.7,
        y: 31.2,
    }, {
        x: 16.7,
        y: 29.7,
    }, {
        x: 17.3,
        y: 25.8,
    }, {
        x: 18.1,
        y: 19,
    }, {
        x: 22.1,
        y: 20.7,
    }, {
        x: 21.9,
        y: 17.7,
    }, {
        x: 11.6,
        y: 17.3,
    }, {
        x: 10,
        y: 13.2,
    }, {
        x: 6.6,
        y: 12.4,
    }, {
        x: 15.1,
        y: 12.1,
    }, {
        x: 18,
        y: 13.1,
    }, {
        x: 26.1,
        y: 11.6,
    }, {
        x: 28,
        y: 7.8,
    }, {
        x: 35.7,
        y: 9.6,
    }, {
        x: 36.3,
        y: 12.5,
    }, {
        x: 29.4,
        y: 13,
    }, {
        x: 27.5,
        y: 15.9,
    }, {
        x: 32.5,
        y: 17.6,
    }, {
        x: 34.1,
        y: 20.8,
    }, {
        x: 33.8,
        y: 22.5,
    }, {
        x: 28.1,
        y: 21.5,
    }, {
        x: 30,
        y: 26.8,
    }]
}, {
    key: 'soc',
    name: "soc",
    arank1: "Enkelados",
    fullname: "The Sea of Clouds",
    aetherytes: [{
        name: "Camp Cloudtop",
        x: 10,
        y: 33.1
    }, {
        name: "Ok' Zundu",
        x: 10.5,
        y: 13.7
    }],
    arank2: "Sisiutl",
    aranks: [{
        x: 7.5,
        y: 8.3,
    }, {
        x: 15.5,
        y: 7.5,
    }, {
        x: 19.3,
        y: 9.5,
    }, {
        x: 23.3,
        y: 10,
    }, {
        x: 21.8,
        y: 7.6,
    }, {
        x: 25.1,
        y: 13.1,
    }, {
        x: 29.4,
        y: 6.6,
    }, {
        x: 37.0,
        y: 8.6,
    }, {
        x: 37.5,
        y: 14.7,
    }, {
        x: 36.6,
        y: 21.1,
    }, {
        x: 31.5,
        y: 19.1,
    }, {
        x: 21.6,
        y: 16,
    }, {
        x: 15.4,
        y: 14.6,
    }, {
        x: 9,
        y: 16.7,
    }, {
        x: 6.5,
        y: 19.3,
    }, {
        x: 14.6,
        y: 23.5,
    }, {
        x: 20.9,
        y: 21.5,
    }, {
        x: 24.5,
        y: 25.3,
    }, {
        x: 13.8,
        y: 29.2,
    }, {
        x: 17.9,
        y: 29.9,
    }, {
        x: 18.3,
        y: 31.7,
    }, {
        x: 26.2,
        y: 29.1,
    }, {
        x: 26.6,
        y: 33.7,
    }, {
        x: 31.7,
        y: 36.2,
    }, {
        x: 34,
        y: 31.6,
    }, {
        x: 36.2,
        y: 38.6,
    }]
}, {
    key: 'azys',
    name: "azys",
    fullname: "Azys Lla",
    arank1: "Campacti",
    aetherytes: [{
        name: "Helix",
        x: 8.1,
        y: 10
    }],
    arank2: "Stench Blossom",
    aranks: [{
        x: 17.4,
        y: 8.5,
    }, {
        x: 18.5,
        y: 13.2,
    }, {
        x: 13.9,
        y: 16.9,
    }, {
        x: 9.5,
        y: 26.5,
    }, {
        x: 11.2,
        y: 30.4,
    }, {
        x: 8.2,
        y: 34,
    }, {
        x: 12.8,
        y: 38.3,
    }, {
        x: 16.1,
        y: 29.4,
    }, {
        x: 29.7,
        y: 35.1,
    }, {
        x: 36.4,
        y: 37.2,
    }, {
        x: 36.3,
        y: 34.2,
    }, {
        x: 35.9,
        y: 30.8,
    }, {
        x: 39,
        y: 27.6,
    }, {
        x: 34.4,
        y: 26.6,
    }, {
        x: 29.9,
        y: 28.8,
    }, {
        x: 33.8,
        y: 12.9,
    }, {
        x: 27.4,
        y: 15.8,
    }, {
        x: 27.7,
        y: 11.2,
    }, {
        x: 32.7,
        y: 5.7,
    }, {
        x: 37.8,
        y: 7.1,
    }]
}, {
    key: 'tdf',
    name: "tdf",
    fullname: "The Dravanian Forelands",
    arank1: "Lord of the Wyverns",
    arank2: "Pylrastor",
    aetherytes: [{
        name: "Anyx Trine",
        x: 16,
        y: 23
    },{
        name: "Tailfeather",
        x: 33.1,
        y: 22.8
    }],
    aranks: [{
        x: 26.4,
        y: 7.5,

    }, {
        x: 33.9,
        y: 8.55,

    }, {
        x: 16.9,
        y: 11.3,

    }, {
        x: 13.6,
        y: 15.05,

    }, {
        x: 32.5,
        y: 18.25,

    }, {
        x: 21.4,
        y: 19.8,

    }, {
        x: 28.9,
        y: 21.8,

    }, {
        x: 25.7,
        y: 24.85,

    }, {
        x: 12.35,
        y: 27.6,

    }, {
        x: 20.05,
        y: 30.2,

    }, {
        x: 27.75,
        y: 30.8,

    }, {
        x: 14.3,
        y: 31.35,

    }, {
        x: 24.9,
        y: 32.4,

    }, {
        x: 21.15,
        y: 33.8,

    }, {
        x: 10.25,
        y: 35.25,

    }, {
        x: 17.95,
        y: 38.75,

    }]
}, {
    key: 'tdh',
    name: "tdh",
    fullname: "The Dravanian Hinterlands",
    arank1: "Slipkinx Steeljoints",
    arank2: "Stolas",
    aranks: [{
        x: 13.1,
        y: 16.45,

    }, {
        x: 26.65,
        y: 16.6,

    }, {
        x: 26.85,
        y: 20.05,

    }, {
        x: 31.8,
        y: 20.15,

    }, {
        x: 9.35,
        y: 21.35,

    }, {
        x: 15.05,
        y: 21.6,

    }, {
        x: 35.95,
        y: 21.85,

    }, {
        x: 5.4,
        y: 22.15,

    }, {
        x: 24.0,
        y: 23.5,

    }, {
        x: 33.95,
        y: 24.1,

    }, {
        x: 15.1,
        y: 25.45,

    }, {
        x: 26.8,
        y: 25.45,

    }, {
        x: 34.2,
        y: 26.85,

    }, {
        x: 8.6,
        y: 28.1,

    }, {
        x: 38.0,
        y: 28.4,

    }, {
        x: 12.6,
        y: 29.25,

    }, {
        x: 26.6,
        y: 29.4,

    }, {
        x: 15.5,
        y: 32.05,

    }, {
        x: 8.45,
        y: 34.0,

    }, {
        x: 14.5,
        y: 35.65,

    }, {
        x: 26.25,
        y: 36.9,

    }, {
        x: 16.15,
        y: 37.75,

    }]
}, {
    key: 'tcm',
    name: "tcm",
    fullname: "The Churning Mists",
    arank1: "Agathos",
    arank2: "Bune",
    aetherytes: [{
        name: "Moghome",
        x: 27.9,
        y: 33.8
    },{
        name: "Zenith",
        x: 10.8,
        y: 28.3
    }],
    aranks: [{
        x: 25.85,
        y: 7.95,

    }, {
        x: 9.5,
        y: 8.5,

    }, {
        x: 28.25,
        y: 10.6,

    }, {
        x: 7.4,
        y: 11.7,

    }, {
        x: 15.2,
        y: 14.5,

    }, {
        x: 7.9,
        y: 15.8,

    }, {
        x: 6.85,
        y: 20.25,

    }, {
        x: 11.9,
        y: 20.3,

    }, {
        x: 28.5,
        y: 20.35,

    }, {
        x: 24.9,
        y: 20.5,

    }, {
        x: 33.5,
        y: 20.85,

    }, {
        x: 14.0,
        y: 23.55,

    }, {
        x: 18.2,
        y: 24.35,

    }, {
        x: 37.2,
        y: 26.2,

    }, {
        x: 26.4,
        y: 27.6,

    }, {
        x: 16.9,
        y: 28.0,

    }, {
        x: 36.05,
        y: 29.05,

    }, {
        x: 15.8,
        y: 30.25,

    }, {
        x: 22.45,
        y: 31.1,

    }, {
        x: 31.95,
        y: 32.65,

    }, {
        x: 6.45,
        y: 35.65,

    }, {
        x: 10.05,
        y: 38.7,

    }]
}]

exports.HWMap = HWMap