import {Button} from "@mui/material";
import React from "react";
import {
    BsFillFileFill,
    BsSortAlphaDown,
    BsSortAlphaDownAlt,
    BsSortNumericDown,
    BsSortNumericDownAlt
} from "react-icons/all";
import {useDispatch, useSelector} from "react-redux";
import {setSortType} from "../state-objects/SortTypeSlice";
import Tooltip from "@mui/material/Tooltip";

export default function SortButtons(props) {

    const sortType = useSelector((state) => state.sortType.value)

    const dispatch = useDispatch()

    const sortOptions = [
        {
            type: "DEFAULT",
            icon: <BsFillFileFill/>,
            popUp: "Default Sort"
        },
        {
            type: "INSTANCES-FIRST",
            icon: <BsSortNumericDownAlt/>,
            popUp: "Instances First"
        },
        {
            type: "INSTANCES-LAST",
            icon: <BsSortNumericDown/>,
            popUp: "Instances Last"
        },
        {
            type: "ALPHABET-DESC",
            icon: <BsSortAlphaDown/>,
            popUp: "Alphabetical"

        },
        {
            type: "ALPHABET-ASC",
            icon: <BsSortAlphaDownAlt/>,
            popUp: "Reverse Alphabetical"
        }]

    function handleClick(v) {
        dispatch(setSortType(v))
    }

    function createButtons() {
        let obj = sortOptions[0]
        let idx = 0
        for (let item in sortOptions) {
            let s = sortOptions[item];

            if (sortType === s.type) {
                idx = item;
                obj = s;
                break
            }
        }


        let next = Number(idx) + 1;
        if (next === sortOptions.length) next = 0;

        return (
            <Tooltip title={obj.popUp} placement="top" arrow>
                <Button size="small" color="secondary"
                        key={"type" + obj.type}
                        onClick={() => handleClick(sortOptions[next].type)} startIcon={obj.icon}>Sort</Button>
            </Tooltip>
        )
            ;
    }

    return (
        <>
            {createButtons()}
        </>
    )
}