const SHCLeads = {

    aiden: {
        name: "Aiden Nightwalker",
        nick: "Aiden",
        pronouns: "-",
        photo: {
            location: "aiden.png",
            align: "top-left"
        },
        since: "A long time",
        about: "Hi there! I've been spawning and running trains for a long time (back when we used to walk up and down the stone path with our minions out trying to figure out how to spawn Nandi). When I'm not doing anything hunt related I am usually trying my best not to be a trap in any content.  If you are interested in anything hunt related or have any questions about Siren Hunts please reach out to myself or any of these wonderful Siren Hunters",
        roles: "Faloop Lead"
    },
    airenlei: {
        name: "Airenlei Saerel",
        nick: "Aiai",
        pronouns: "She/Her",
        photo: {
            location: "airenlei2.png",
            align: "top-left"
        },
        since: "Like a year before EW maybe.",
        about: "idk fam",
        roles: "SHC Admin, SH Web Admin, Little Lala Creator"
    },
    arolas: {
        name: "Arolas Legwen",
        nick: "Aro",
        pronouns: "She/Her",
        photo: {
            location: "arolas.png",
            align: "bottom-left"
        },
        since: "June 2021 (around Patch 5.57)",
        about: "just me - always rioting, always sounding angy, love foods, love boba, love furbabies, plays siege, volleyball is life, student, and love cooking and baking for friends and family. In-Game - savage raider, indecisive designer for FC, and sometimes hunts achievements (dammit Kharnak). Hunts - I like spinning, doing dumb things, and /stalking fresh meat/ helping/inducting new hunters",
        roles: "Faloop Lead"
    },
    beau: {
        name: "Beau Bellator",
        nick: "Beau",
        pronouns: "They/Them",
        photo: {
            location: "beau.png",
            align: "bottom-right"
        },
        since: "Patch 2.3 (Naul Pls)",
        about: "Hi I'm Beau! I've been spawning and hunting since the very start of hunts! While I tend to take more of a quiet route to hunting now, I am always open to answer any questions about hunts. If I'm not going feral on hunts you will usually find me in Eureka/Bozja or crying at some fishing windows.",
        roles: "Faloop Lead"
    },
    freya: {
        name: "Freya Tennebrae",
        nick: "Freya",
        pronouns: "She/Her",
        photo: {
            location: "freya.png",
            align: "bottom-left"
        },
        since: "Late Shadowbringers",
        about: "I am always tired, I like boolet trains, and I take payment for all hunt services in Triple Cream Coffee. :)",
        roles: "Faloop Lead, AH Rep"
    },
    harbinger: {
        name: "Harbinger Prime",
        nick: "Harbinger, one can assume",
        pronouns: "He/Him",
        photo: {
            location: "harbiny.png",
            align: "bottom-right"
        },
        since: "Late ~5.5",
        about: "I started by riding trains before finishing the MSQ then started scouting here and there before finally asking someone what more I could do. Now it's mostly spawning, with scouting and/or conducting. Otherwise I like to fish and collect all the things along with doing all the content. Feel free to reach out with comments, questions etc.",
        roles: "Faloop Lead, Prime Hunts Web Admin"
    },
    kai: {
        name: "Kai Ju",
        nick: "Kai, one can assume",
        pronouns: "They/Them",
        photo: {
            location: "kai.png",
            align: "bottom-left"
        },
        since: "Stormblood",
        about: "I’m Kai, I’m an achievement hunter, hardcore raider, mount collector, champion of the common man, and general completionist. I’m always happy to help new or old players enter the hunt scene as well as offer advice on anything else.",
        roles: "Faloop Lead, AH Rep"
    },
    kharnak: {
        name: "Kharnak Kalyeska",
        nick: "Kharnak",
        pronouns: "He/Him",
        photo: {
            location: "kharnak.png",
            align: "bottom-right"
        },
        since: "3 Years",
        about: "Emoting, mount parading Hrothgar chasing fun (mostly collectibles and achievements) in every corner of Etheirys. Cursed completionist, apparently the resident old-timer, and the bluest White Mage.",
        roles: "SHC Admin, Faloop Lead, AH Mod"
    },
    petite: {
        name: "Petite Cat",
        nick: "Petite",
        pronouns: "She/Her",
        photo: {
            location: "petite-cat.png",
            align: "bottom-left"
        },
        since: "Patch 5.4",
        about: "Henlo, it me Petite Cat. I started out scouting for SHB and SB trains back in Patch 5.4, got into relaying, created some linkshells, developed a functioning gambling addiction through spawning S-Ranks, conducted my first train on Aether Hunts where I forgot to ping the train role until the second zone, joined this cult called the Siren Hunts Discord… and now I’m here revising this blurb. If you are interested in scouting, relaying, conducting a hunt train, or spawning S-Ranks on Siren, please don’t hesitate to reach out to me. You can message me on Discord @petite_cat or /tell Petite Cat@Siren in-game, and I’ll initiate your entry into our cult.",
        roles: "SHC Admin, Faloop Lead, \nCH Mod, AH Mod"
    },
    vash: {
        name: "Vashet Saicere",
        nick: "Vash",
        pronouns: "She/Her",
        photo: {
            location: "vashet.png",
            align: "top-left"
        },
        since: "Hunting since: 4.1",
        about: "Misplaced European. \"Living fossil\" of hunts, mostly keeping to the backstage these days. Miss pre world visit hunts. Still like it when the A ranks fight back. Spawning/fishing encyclopedia of sorts\n" +
            "The Doors of Stone is never coming out",
        roles: "Faloop Lead"
    },
    yumiko: {
        name: "Yumiko Kaneko",
        nick: "Yumi",
        pronouns: "(ᴉo ᴉo ᴉo) ǝᴉssn∀ ǝᴉssn∀ ǝᴉssn∀",
        photo: {
            location: "yumiko.png",
            align: "top-right"
        },
        since: "Yumi has always been here and there and everywhere all at once.",
        about: "One of the few Australian hunters in NA. Appears as a dead BLU in the overworld. Likes spawning multiple things at once for maximum chaos.",
        roles: "SHC Admin, Faloop Lead"
    }
}

export default SHCLeads