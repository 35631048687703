import {createSlice} from "@reduxjs/toolkit";

export const ScoutingMarksSlice = createSlice({
    name: 'scoutingMarks',
    initialState: {
        value: {},
    },
    reducers: {
        addScoutingMarks: (state, action) => {
            let expansion = action.payload.expansion
            let map = action.payload.map
            let instance = action.payload.instance
            let index = action.payload.index
            let status = action.payload.status
            let timeStamp = action.payload.timeStamp
            let instancedMap = state.value[expansion + "-" + map + "-" + instance]
            for (let mapIndex in instancedMap) {
                let spot = instancedMap[mapIndex]
                if (spot.index === index) {
                    state.value[expansion + "-" + map + "-" + instance].splice(mapIndex, 1);
                    break
                }
            }

            if (state.value[expansion + "-" + map + "-" + instance] === undefined) {
                let newMark = {"index": index, "status": status, "timeStamp": timeStamp}
                state.value[expansion + "-" + map + "-" + instance] = [newMark,]
            } else {
                state.value[expansion + "-" + map + "-" + instance].push({index: index, status: status, timeStamp: timeStamp})
            }
        },
        removeScoutingMarks: (state, action) => {
            let expansion = action.payload.expansion
            let map = action.payload.map
            let instance = action.payload.instance
            let index = action.payload.index
            let status = action.payload.status
            let instancedMap = state.value[expansion + "-" + map + "-" + instance]
            for (let mapIndex in instancedMap) {
                let spot = instancedMap[mapIndex]
                if (spot.index === index && spot.status === status) {
                    state.value[expansion + "-" + map + "-" + instance].splice(mapIndex, 1);
                    break
                }
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { addScoutingMarks, removeScoutingMarks } = ScoutingMarksSlice.actions

export default ScoutingMarksSlice.reducer