function changeToMarkStatus(mark_status) {
    let mkstatus = 0;
    if (mark_status === "mark1") mkstatus = 1
    else if (mark_status === "mark2") mkstatus = 2
    return mkstatus;
}

export default function ScoutingUpdate(expansion, map, instance, spot_idx, mark_status, currentTime) {
    let mkstatus = changeToMarkStatus(mark_status);
    return "{" +
        "  \"expansion\":\"" + expansion + "\"," +
        "  \"map\":\"" + map + "\"," +
        "  \"instance\":" + instance + "," +
        "  \"index\":" + spot_idx + "," +
        "  \"status\":" + mkstatus + "," +
        "  \"timeStamp\":" + currentTime +
        "}";
}