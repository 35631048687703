import ExpansionMap from "../data/ExpansionData";
import HashDecoder from "../share-link/HashDecoder";
import ToggleSpot from "./coord-spots/ToggleSpot";
import {setLoaded} from "../state-objects/LoadedSlice";

function countARanks(map, exp) {
    let norm = ExpansionMap[exp].multiplier

    let num = 0;

    map.forEach(item => {
        let inst = 1;
        if (item.instances !== undefined) inst = item.instances;
        num = num + (inst * norm)
    })
    return num;
}

export default function LoadScoutingData(scoutCode, scoutingMarks, databaseOn, databaseCode, dispatch) {
    let fields = scoutCode.replace("%3E", ">").split(">")
    let lowerCase = fields[0].toLowerCase();
    let scoutData = fields[1]

    let data = ExpansionMap[lowerCase]

    if (data === undefined) return undefined

    let aRanks = countARanks(data.map, lowerCase)
    let decoded = HashDecoder(scoutData, aRanks)

    let copy = [...decoded]

    for (let idx in data.map) {
        let item = data.map[idx]
        let inst = 1;
        if (item.instances !== undefined) inst = item.instances;
        let currentTime = (new Date()).getTime();
        for (let i = 1; i < inst + 1; i++) {

            ToggleSpot(lowerCase, item.name, i, copy.pop(), "mark1", currentTime, scoutingMarks, databaseOn, databaseCode, dispatch)
            if (lowerCase !== "arr") {
                ToggleSpot(lowerCase, item.name, i, copy.pop(), "mark2", currentTime, scoutingMarks, databaseOn, databaseCode, dispatch)
            }
        }
    }

    dispatch(setLoaded(true))

    return lowerCase;
}